import React from "react";
import { cardOrientation } from "../../../assets/util/common/cardOrientation";
import DoctorCard from "./DoctorCard/DoctorCard";
import { useSelector } from "react-redux";
import Loader from "Pages/Loader";

const DoctorList = () => {
  const DoctorList = useSelector(
    (state) => state.R_GetDoctorsListBySpecialityID
  );
  return (
    <div
      className="container-fluid mt-2"
      style={{
        ...cardOrientation,
        minHeight: "140vh",
        maxHeight: "160vh",
        overflowY: "auto",
      }}
    >
      <h3 className="mainheaderdoctor">All Doctor</h3>
      {DoctorList?.loading ? (
        <div className="centerLoader1">
          <Loader />
        </div>
      ) : (
        <div className="row flex flex-wrap all-doctors-list">
          {DoctorList?.data?.length ? (
            DoctorList?.data?.map((value, i) => {
              return <DoctorCard key={i} data={value} />;
            })
          ) : (
            <p className="text-center">No Data Found</p>
          )}
        </div>
      )}
    </div>
  );
};

export default DoctorList;
