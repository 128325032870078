import React, { useEffect, useLayoutEffect, useState } from "react";
import { cardOrientation, rootButton } from "assets/util/common/root";
import "./PatientName.css";
import doctor from "assets/image/doctor.png";
import { useDispatch, useSelector } from "react-redux";
import { anteMeridiemFormat, getLocalFormat } from "common/days";
import { getImg } from "common/imageUrl";
import API from "util/API/api";
import { postThunk } from "Action/middleware/thunk";
import { callGet, callSet } from "common/local";
import { toast } from "react-hot-toast";
import usePost from "Hooks/usePost";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { postAPIData } from "Hooks/Services";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Icon } from "@iconify/react";
import Loader from "Pages/Loader";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Slide } from "@mui/material";
const ValidatorSchema = yup
  .object()
  .shape({
    UHID: yup.string().required("UHID is required"),
  })
  .when((values, schema) => {
    if (values?.email?.trim()) {
      return schema?.shape({
        email: yup
          .string()
          .email("Please enter a valid email")
          .matches(
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
            "Please enter a valid email"
          ),
      });
    }
  })
  .when((values, schema) => {
    if (values?.UHID === "other") {
      return schema.shape({
        typeUHID: yup
          .string()
          .min(9, "Enter a valid UHID")
          .max(9, "Enter a valid UHID")
          .required("UHID is required"),
      });
    }
  });
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const PatientName = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [doctorFeesLoading, setDoctorFeesLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [emialConfirm, setEmailConfirm] = useState(false);
  const [currFormData, setCurrFormData] = useState({});
  const authData = callGet("auth");
  const [isAlertViewed, setIsAlertViewed] = useState(false);
  const [loading1, getData, setApi] = usePost();
  const [UHIDList, setUHIDList] = useState("");
  const [doctorProfile, setDoctorProfile] = useState({});
  const [uhidVal, setUHIDVal] = useState("");
  const [uhidResp, setUhidResp] = useState({});
  const appointment = useSelector((state) => state?.R_savePatientAppoint);
  const navigate = useNavigate();
  const [payLaterSuccess, setPayLaterSuccess] = useState(false);
  const [infoLoading, setInfoLoading] = useState(false);
  const paymentDetails = useSelector((state) => state?.R_getdoctorfees);
  const [patientInput, setPatientInput] = useState({
    UHID: "",
    patientName: "",
    userId: "",
    email: "",
  });
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      UHID: "",
      email: "",
      typeUHID: "",
    },
    resolver: yupResolver(ValidatorSchema),
  });

  function handleAppoint(e) {
    let selectedUser = UHIDList.find((f) => f?.UHID === e?.target?.value);
    setValue("email", selectedUser?.EmailId?.trim() || "");
    setPatientInput((a) => ({
      ...a,
      UHID: selectedUser?.UHID || "",
      patientName: selectedUser?.Name || "",
      userId: selectedUser?.MobileNumber || "",
      email: selectedUser?.EmailId || "",
    }));
  }

  // const callDoctorFees = async (curr_uhid) => {
  //   const authData = callGet("auth");
  //   var body = {
  //     ...authData,
  //     DoctorId: appointment?.doctorDetail?.DoctorId,
  //     FacilityId: authData?.hospitalId,
  //     UHID: curr_uhid || appointment?.takeAppointment?.UHID,
  //     TimeSlot: appointment?.slot?.TimeSlot,
  //     TimeSlotId: appointment?.slot?.SlotId,
  //     AppointmentDate: appointment?.takeAppointment?.Date,
  //   };
  //   dispatch(postThunk(API.A_getdoctorfees(body)));
  // };
  // useEffect(() => {
  //   if (appointment?.doctorDetail?.DoctorId && appointment?.slot?.TimeSlot) {
  //     callDoctorFees();
  //   }
  // }, [appointment?.doctorDetail?.DoctorId, appointment?.slot?.TimeSlot]);
  // console.log(doctorProfile);
  const getDcotorProfile = async (data) => {
    try {
      setLoading(true);
      const resp = await postAPIData({ ...API.getDcotorProfile(data) });
      const respData = JSON.parse(resp?.data);
      setDoctorProfile(respData?.Data?.[0]);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const getDcotorFees = async (curr_uhid) => {
    const authData = callGet("auth");
    var body = {
      ...authData,
      DoctorId: appointment?.doctorDetail?.DoctorId,
      FacilityId: authData?.hospitalId,
      UHID: curr_uhid || appointment?.takeAppointment?.UHID,
      TimeSlot: appointment?.slot?.TimeSlot,
      TimeSlotId: appointment?.slot?.SlotId,
      AppointmentDate: appointment?.takeAppointment?.Date,
    };
    try {
      setDoctorFeesLoading(true);
      const resp = await postAPIData({ ...API.getDoctorFees(body) });
      const respData = JSON.parse(resp?.data);
      if (respData?.FriendlyMessage === "NO RECORDS FOUND") {
        throw respData;
      }
      dispatch({ type: "GET_DOC_FEE", payload: respData?.Data, err: "" });
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      setDoctorFeesLoading(false);
    }
  };
  useEffect(() => {
    const authData = callGet("auth");
    if (appointment?.doctorDetail?.DoctorId) {
      getDcotorProfile({
        ...authData,
        DoctorId: appointment?.doctorDetail?.DoctorId,
        FacilityId: authData?.selectedHospitalId || authData?.hospitalId,
      });
    }
  }, [appointment?.doctorDetail?.DoctorId]);
  useEffect(() => {
    if (getData) {
      if (getData?.IsSuccessful) {
        setUHIDList(getData?.Data);
      } else {
        toast.error(getData?.FriendlyMessage);
      }
    }
  }, [getData]);
  useEffect(() => {
    dispatch({ type: "REMOVE_DATA" });
    var body = { ...callGet("auth") };
    setApi(API.getuhidlistbymobile(body));
    window.scroll(0, 0);
  }, []);
  const UHIDInfo = async (data) => {
    try {
      setInfoLoading(true);
      const resp = await postAPIData({
        ...API.getuhidinfo({ ...callGet("auth"), UHID: data }),
      });
      if (!resp?.data?.IsSuccessful) {
        throw resp?.data;
      }
      const respData = resp?.data?.Data?.[0];
      setUhidResp(respData);
      setValue("email", respData?.EmailId?.trim() || "");
      setPatientInput((a) => ({
        ...a,
        UHID: respData?.UHID || "",
        patientName: respData?.Name || "",
        userId: respData?.MobileNumber || "",
        email: respData?.EmailId?.trim() || "",
      }));
    } catch (err) {
      console.error(err);
      toast.error(err?.FriendlyMessage || "Something went wrong");
    } finally {
      setInfoLoading(false);
    }
  };
  const getFormData = async (data) => {
    let curr_uhid = data?.UHID === "other" ? data?.typeUHID : data?.UHID;
    if (!patientInput?.patientName || !patientInput?.userId) {
      toast.error("Please enter valid UHID");
      return;
    }
    if (!data?.email && !data?.bool && !emialConfirm && !isAlertViewed) {
      setCurrFormData(data);
      setIsAlertViewed(true);
      setEmailConfirm(true);
      return;
    } else {
      setEmailConfirm(false);
    }
    const authData = callGet("auth");
    try {
      setLoading(true);
      await getDcotorFees(curr_uhid);
      if (
        doctorProfile?.PaymentOptionalForAppointment &&
        appointment?.takeAppointment?.AppointmentTypeCode !== "TS"
      ) {
        setOpen(true);
      } else {
        const txnId = `PA1${new Date().getTime()}`;
        callSet({
          name: "auth",
          data: {
            ...authData,
            TxnID: txnId,
          },
        });
        dispatch({
          type: "GET_PAT_DETAIL",
          payload: {
            ...patientInput,
            email: data?.email?.trim() || "",
            doctorDetails: doctorProfile,
          },
        });
        navigate("payment");
      }
    } catch (err) {
      console.error(err);
      toast.error(err?.FriendlyMessage || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };
  const payNow = async (data) => {
    const txnId = `PA1${new Date().getTime()}`;
    callSet({
      name: "auth",
      data: {
        ...authData,
        TxnID: txnId,
      },
    });
    dispatch({
      type: "GET_PAT_DETAIL",
      payload: {
        ...patientInput,
        email: data?.email?.trim() || "",
        doctorDetails: doctorProfile,
      },
    });

    navigate("payment");
  };
  const payLater = async (data) => {
    const authData = callGet("auth");
    var body = {
      ...authData,
      DoctorId: appointment?.doctorDetail?.DoctorId,
      FacilityId: authData?.selectedHospitalId || authData?.hospitalId,
      UHID: data?.UHID,
      TimeSlot: appointment?.slot?.TimeSlot,
      TimeSlotId: appointment?.slot?.SlotId,
      AppointmentDate: appointment?.takeAppointment?.Date,
      IsPayLaterAppointment: true,
      Interval: appointment?.slot?.Interval || "",
      RescheduleTimeSlotId: appointment?.reScheduleSlot?.SlotId,
      RescheduleInterval: appointment?.reScheduleSlot?.Interval,
      RescheduleTimeSlot: appointment?.reScheduleSlot?.TimeSlot,
      AppointmentTypeID: appointment?.takeAppointment?.AppointmentTypeID,
      AppointmentTypeCode: appointment?.takeAppointment?.AppointmentTypeCode,
    };
    try {
      setLoading(true);
      setOpen(false);
      const resp = await postAPIData({ ...API.A_takeappointment({ ...body }) });
      if (resp?.data?.IsSuccessful) {
        setPayLaterSuccess(true);
        setOpen(true);
      } else {
        throw resp?.data;
      }
      dispatch({ type: "DELETE_PATIENT_APPOINT" });
      navigate("/dashboard", { replace: true });
      navigate(
        `/dashboard/redirects/payment?type=appointment&success=true&id=${resp?.data?.Data?.[0]?.AppointmentId}`
      );
    } catch (err) {
      toast.error(err.FriendlyMessage || "Something went wrong !!");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="container-fluid mt-2  p-4" style={cardOrientation}>
      <Dialog
        open={emialConfirm}
        className="appointment-payment-confirmation-1"
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setEmailConfirm(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          onClick={() => {
            setEmailConfirm(false);
          }}
          className="cross-icon-confirm"
        >
          <Icon fontSize={"20px"} icon="maki:cross" color="#0d6efd" />
        </div>
        <DialogTitle>
          {authData?.hospitalName || "Marengo Asia Hospital, Faridabad"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            You shall not receive invoice through email in case you do not enter
            any email id. <br />
            Do you wish to continue ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEmailConfirm(false)}>Cancel</Button>
          <Button onClick={() => getFormData({ ...currFormData, bool: true })}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {/* Email Confirm */}
      <Dialog
        className="appointment-payment-confirmation"
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          onClick={() => {
            handleClose();
          }}
          className="cross-icon-confirm"
        >
          <Icon fontSize={"20px"} icon="maki:cross" color="#0d6efd" />
        </div>
        <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
        <DialogContent>
          {!payLaterSuccess && (
            <DialogContentText id="alert-dialog-description">
              <p>
                {" "}
                You are about to book an appointment with{" "}
                {doctorProfile?.DoctorName} for{" "}
                {appointment?.takeAppointment?.Date}{" "}
                {moment(appointment?.slot?.TimeSlot, "hh:mm a").format(
                  "hh:mm a"
                )}{" "}
                at {doctorProfile?.Facilities?.[0]?.HospitalName}.
              </p>
              <p>
                Click on Pay now, to confirm your appointment with the doctor.
                You may choose to Pay later, but the appointment shall remain
                unconfirmed
              </p>
            </DialogContentText>
          )}
          {payLaterSuccess && (
            <DialogContentText id="alert-dialog-description-paylater">
              <b>{doctorProfile?.Facilities?.[0]?.HospitalName}</b>
              <p>
                Your appointment has been booked with{" "}
                {doctorProfile?.DoctorName} for{" "}
                {appointment?.takeAppointment?.Date}{" "}
                {moment(appointment?.slot?.TimeSlot, "hh:mm a").format(
                  "hh:mm A"
                )}{" "}
                at {doctorProfile?.Facilities?.[0]?.HospitalName}. Payment for
                the appointment will be collected at the time of check-in at the
                reception.
              </p>
            </DialogContentText>
          )}
        </DialogContent>
        {!payLaterSuccess && (
          <DialogActions>
            <Button
              className="pay-now-btn"
              onClick={() => {
                payNow({
                  email: getValues("email"),
                  UHID:
                    getValues("UHID") === "other"
                      ? uhidResp?.UHID || getValues("typeUHID")
                      : getValues("UHID"),
                });
              }}
            >
              Pay Now
            </Button>
            <Button
              className="pay-later-btn"
              onClick={() => {
                payLater({
                  email: getValues("email"),
                  UHID:
                    getValues("UHID") === "other"
                      ? uhidResp?.UHID || getValues("typeUHID")
                      : getValues("UHID"),
                });
              }}
            >
              Pay Later
            </Button>
          </DialogActions>
        )}
        {payLaterSuccess && (
          <DialogActions>
            <Button
              className="pay-now-btn"
              onClick={() => {
                navigate("/dashboard/myAppointment");
                setOpen(false);
              }}
            >
              Ok
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <div className=" d-flex">
        <div>
          <div className="media d-flex ">
            <img
              id="profile"
              draggable="false"
              width="60"
              height="60"
              src={
                appointment?.doctorDetail?.DoctorPic
                  ? getImg(appointment?.doctorDetail?.DoctorPic)
                  : doctor
              }
              alt="doctor pic"
            />
            <div class="media-body ms-3 ">
              <h6 class="mt-0">
                <b>{appointment?.doctorDetail?.DoctorName}</b>
              </h6>
              <p>
                {getLocalFormat(appointment?.takeAppointment?.Date)},{" "}
                {anteMeridiemFormat(appointment?.slot?.TimeSlot)}
              </p>
            </div>
          </div>
        </div>
        <div className="ms-auto">
          <button
            onClick={() => navigate("/dashboard/patient-registration")}
            style={rootButton}
          >
            Register With Us
          </button>
        </div>
      </div>

      <hr />

      {/* Form Section */}
      <div className="row">
        <div>
          <div className="form">
            <div className="col-md-4">
              <div className="form-group mt-2 mb-2">
                <select
                  {...register("UHID", {
                    onChange: (e) => {
                      setValue("typeUHID", "");
                      setUHIDVal("");
                      handleAppoint(e);
                    },
                  })}
                  name="UHID"
                  id="option_design"
                  class="form-select  p-2"
                  aria-label="Default select example"
                  required
                >
                  <option value="">Select UHID</option>
                  {UHIDList?.length > 0 ? (
                    [...UHIDList, { UHID: "other", value: "OTHERS" }]?.map(
                      (data, i) => (
                        <option key={i} value={data?.UHID}>
                          {data?.value
                            ? data?.value
                            : `${data?.UHID} - ${data?.Name}`}
                        </option>
                      )
                    )
                  ) : (
                    <option value={""}>Loading...</option>
                  )}
                </select>
                {errors?.["UHID"] && (
                  <p className="errorText">
                    {errors?.["UHID"]?.message?.toString()}
                  </p>
                )}
              </div>
            </div>
            {getValues("UHID") === "other" && (
              <div className="col-md-4">
                <input
                  {...register("typeUHID", {
                    onChange: (e) => {
                      if (e?.target?.value?.length > 9) {
                        setValue("typeUHID", uhidVal);
                        return;
                      }
                      setUHIDVal(e?.target?.value);
                      if (e?.target?.value?.length === 9) {
                        UHIDInfo(e?.target?.value);
                      }
                    },
                  })}
                  name="typeUHID"
                  type="number"
                  min={0}
                  max={999999999}
                  className="form-control p-2"
                  placeholder="Enter UHID*"
                />
                {errors?.["typeUHID"] && (
                  <p className="errorText">
                    {errors?.["typeUHID"]?.message?.toString()}
                  </p>
                )}
              </div>
            )}
            <div className="col-md-4 ">
              <div className="form-group mt-2 mb-2 ">
                <input
                  disabled
                  value={patientInput?.patientName}
                  id="input_design"
                  className="form-control"
                  type="text"
                  placeholder="Patient Name"
                  required
                  name="patientName"
                  onChange={handleAppoint}
                />
              </div>
            </div>
            <div className="col-md-4 ">
              <div className="form-group mt-2 mb-2">
                <input
                  disabled
                  value={patientInput?.userId}
                  id="input_design"
                  className="form-control "
                  type="number"
                  placeholder="Mob. Number"
                  required
                  name="userId"
                  onChange={handleAppoint}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group mt-2 mb-2">
                <input
                  {...register("email")}
                  name="email"
                  id="input_design"
                  className="form-control"
                  type="email"
                  placeholder="Mail ID"
                />
                {errors?.["email"] && (
                  <p className="errorText">
                    {errors?.["email"]?.message?.toString()}
                  </p>
                )}
              </div>
            </div>
            <p>
              By booking this appointment,you agree to the{" "}
              <span
                onClick={() => {
                  navigate("/dashboard/termsConditions");
                }}
                style={{
                  color: "#FF6F0F",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                Terms and Conditions
              </span>
            </p>
            {((!paymentDetails?.loading && paymentDetails?.err) ||
              (!loading && !doctorProfile?.DoctorName)) && (
              <p style={{ color: "red" }}>
                *{paymentDetails?.err || "Doctor details not found"}
              </p>
            )}

            <div>
              <button
                disabled={!doctorProfile?.DoctorName}
                onClick={handleSubmit(getFormData)}
                type="submit"
                style={{
                  ...rootButton,
                  backgroundColor: "#007DC6",
                  cursor: "pointer",
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
        {(loading || loading1 || paymentDetails?.loading || infoLoading) && (
          <div className="loader-background">
            <div className="centerLoader1">
              <Loader />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PatientName;
