import React, { useEffect, useState } from "react";
import "./signUp.css";
import mahospital from "../assets/image/mahospitalmain.png";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import MainIcon from "../assets/mainIcon/MainIcon";
import { callGet } from "common/local";
import usePost from "../Hooks/usePost";
import API from "util/API/api";
import { signupCredentialSchema } from "../schema/allSchema";
import { toast } from "react-hot-toast";
import { LoadingButton } from "../assets/loading/LoadingButton";
import MainModal from "../assets/modal/MainModal";
import Box from "@mui/material/Box";
import { linkStyle } from "../assets/util/common/root";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { postAPIData } from "Hooks/Services";
import Loader from "Pages/Loader";

const schema = yup.object().shape({
  FullName: yup
    .string()
    .required("Name is required")
    .test("WhiteSpaces", "Name is required", (data) => !!data?.trim())
    .min(3),
  userid: yup
    .string()
    .required("Number is required")
    .min(10, "Phone number is not valid")
    .matches(/(0|91)?[6-9][0-9]{9}/, "Phone number is not valid")
    .test("numberCheck", "Phone number is not valid", (val) =>
      val?.toString()?.startsWith(0)
        ? val.length === 11
        : val?.toString()?.startsWith(91)
          ? val?.length === 12
          : val?.length === 10
    ),
  checkbox: yup
    .bool()
    .required("This field is required")
    .test("check", "This field is required", (val) => val === true),
});



// const style = {
//   // boxSizing:"border-box",
//   position: "absolute",
//   left: "27.25%",
//   right: "27.19%",
//   top: "10.93%",
//   bottom: "14.67%",
//   background: " #FFFFFF",
//   opacity: "0.9",
//   border: "1px solid #007DC6",
//   boxShadow: "1px 1px 20px 1px rgba(0, 0, 0, 0.1)",
//   borderRadius: "10px",
// };

function SignUp() {
  const [open, setOpen] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(0);
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [signupCredential, setSignupCredential] = useState(
    signupCredentialSchema
  );
  let timerS;
  const [formError, setFormError] = useState({});
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: { ...signupCredentialSchema, checkbox: false },
    resolver: yupResolver(schema),
  });
  function handleForm(e) {
    if (e.target.name === "OTP" && e.target.value?.length > 4) {
      return;
    }
    setSignupCredential({
      ...signupCredential,
      [e.target.name]: e.target.value,
    });
  }
  const postMatchData = async (data) => {
    try {
      setLoading(true);
      const resp = await postAPIData({ ...data });
      if (resp?.data?.IsSuccessful) {
        setShowOTPInput(false);
        setTimer(0);
        handleOpen();
        data?.OTPRequired && toast.success(resp?.data?.FriendlyMessage);
      } else {
        throw resp?.data;
      }
    } catch (err) {
      console.error(err);
      toast.error(err?.FriendlyMessage || "Something went wrong");
      setValue("checkbox", true);
    } finally {
      setLoading(false);
      setValue("checkbox", true);
    }
  };
  const checkAPIVersionOTP = async (data) => {
    try {
      const resp = await postAPIData({ ...API.VerifyAppVersionOTP() });
      if (resp?.data?.OTPRequired) {
        getOTPData(data);
      } else {
        data.body.OTPRequired = false;
        getOTPData({ ...data });
      }
    } catch (err) {
      console.error(err);
      setValue("checkbox", true);
    } finally {
    }
  };
  const getOTPData = async (data) => {
    try {
      setLoading(true);
      const resp = await postAPIData({ ...data });
      if (resp?.data?.IsSuccessful) {
        if (data?.body?.OTPRequired === false) {
          setSignupCredential((a) => ({
            ...a,
            OTPCode: resp?.data?.Data[0]?.OTPCode,
            OTP: "1234",
          }));
          setValue("OTP", "1234");
          setValue("OTPCode", resp?.data?.Data[0]?.OTPCode);
          handleSubmit(getFormData)();
          return;
        }
        setShowOTPInput(true);
        setTimer(59);
        setSignupCredential((a) => ({
          ...a,
          OTPCode: resp?.data?.Data[0]?.OTPCode,
          OTP: "",
        }));
        toast.success(resp?.data?.FriendlyMessage);
      } else {
        throw resp?.data;
      }
    } catch (err) {
      console.error(err);
      toast.error(err?.FriendlyMessage || "Something went wrong");
    } finally {
      setLoading(false);
      setValue("checkbox", true);
    }
  };
  const getandMathOtp = async (data) => {
    let otpValid = false;
    if (signupCredential?.OTPCode || data?.OTPCode) {
      data.OTPCode = signupCredential.OTPCode || data?.OTPCode || "";
      data.OTP = signupCredential?.OTP || data?.OTP || "";
      try {
        await yup
          .string()
          .required("OTP is required")
          .min(4, "Enter valid OTP")
          .max(4, "Enter valid OTP")
          .validate(signupCredential?.OTP || data?.OTP);

        setFormError({});
        otpValid = true;
      } catch (err) {
        otpValid = false;
        setFormError((prev) => ({ ...prev, otp: err.message }));
      }
      if (otpValid) {
        delete data.checkbox;
        postMatchData({ ...API?.patientMatchOtp(data) });
      }
    } else {
      delete data.checkbox;
      // getOTPData({ ...API.getOtp(data) });
      checkAPIVersionOTP({ ...API.getOtp(data) });
    }
  };
  const getResendFormData = async (data) => {
    if (timer > 0) {
      return;
    }
    setSignupCredential({ ...signupCredential, OTPCode: "" });
    await getOTPData({ ...API.getOtp(data) });
  };
  const getFormData = (data) => {
    getandMathOtp(data);
  };
  useEffect(() => {
    localStorage.clear();
  }, []);
  useEffect(() => {
    timerS = setTimeout(() => {
      setTimer((t) => t - 1);
    }, 1000);
    if (timer === 0) clearTimeout(timerS);
  }, [timer]);
  if (callGet("auth")?.ClientToken) {
    return <Navigate to="/dashboard" />;
  } else {
    return (
      <React.Fragment>
        <div
          className="container-fluid rootBackground d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <MainIcon top={0} left={10} width={350} />
          <div className="signUpBox shadow-lg bg-light">
            <div className="text-center">
              <img
                src={mahospital}
                draggable="false"
                height="auto"
                width="200"
                style={{ padding: "50px 0" }}
                alt="Marengo Asia Hospital Logo"
              />
              <h3 className="text-primary" style={{ fontWeight: "900" }}>
                <strong>Create an Account</strong>
              </h3>
            </div>
            <div className="ps-5 pe-5 pb-5">
              {/* fullName no */}
              <div className="form-group mt-2">
                <input
                  {...register("FullName")}
                  type="text"
                  name="FullName"
                  className="iphoneFix p-3"
                  aria-describedby="nameHelp"
                  placeholder="Enter Full Name"
                />
                {errors?.["FullName"] && (
                  <p className="errorText">
                    {errors?.["FullName"]?.message?.toString()}
                  </p>
                )}
              </div>
              <div className="form-group mt-2">
                <input
                  {...register("userid", {
                    onChange: (e) => {
                      if (e?.target?.value?.length > 10) {
                        setValue("userid", signupCredential?.userid);
                        return;
                      }
                      setSignupCredential({
                        ...signupCredential,
                        userid: e?.target?.value,
                      });
                    },
                  })}
                  type="number"
                  maxLength="10"
                  minLength={"10"}
                  name="userid"
                  className="iphoneFix p-3"
                  aria-describedby="mobileHelp"
                  placeholder="Enter Mobile Number"
                />
                {errors?.["userid"] && (
                  <p className="errorText">
                    {errors?.["userid"]?.message?.toString()}
                  </p>
                )}
              </div>
              {/* otp section */}
              {showOTPInput && (
                <div className="form-group mt-2">
                  <input
                    onChange={handleForm}
                    value={signupCredential.OTP}
                    type="number"
                    maxLength="4"
                    pattern="\d{4}"
                    name="OTP"
                    className="form-control p-3"
                    aria-describedby="OTPHelp"
                    placeholder="Enter OTP"
                  />
                  {formError["otp"] && (
                    <p className="errorText">{formError["otp"]}</p>
                  )}
                </div>
              )}
              {/* checkbox */}

              {showOTPInput && (
                <div
                  onClick={handleSubmit(getResendFormData)}
                  style={{
                    color: "rgb(255, 111, 15)",
                    cursor: timer === 0 && "pointer",
                    textDecoration: "underline",
                    pointerEvents: timer > 0 && "none",
                  }}
                >
                  {timer > 0 ? `Resend OTP in ${timer}` : "Resend OTP"}
                </div>
              )}
              <div className="form-check mt-3 mb-3">
                <input
                  {...register("checkbox")}
                  type="checkbox"
                  name="checkbox"
                  style={{ cursor: "pointer" }}
                  className="form-check-input"
                // id="exampleCheck1"
                />
                <label style={{display:"flex",flexWrap:"wrap"}} className="form-check-label" htmlFor="exampleCheck1">
                  I Agree to the{" "}
                  <span>
                  <p className="spanios"
                    onClick={() => {
                      navigate("/termsConditions");
                    }}
                  />
                    {" "}
                    Terms and Conditions
                  </span>
                  ,{" "}
                  <span
                    onClick={() => {
                      navigate("/privacyPolicy");
                    }}
                  >
                    Privacy Policy
                  </span>{" "}
                  and compliance towards{" "}
                  <span
                    onClick={() => {
                      navigate("/dpdp-act");
                    }}
                  >
                    {" "}
                    DPDP Act
                  </span>
                </label>
                {errors?.["checkbox"] && (
                  <p className="errorText">
                    {errors?.["checkbox"]?.message?.toString()}
                  </p>
                )}
              </div>
              {/* submit */}
              <div style={{ display: "inline", marginTop: "10px" }}>
                <LoadingButton
                  onClick={handleSubmit(getFormData)}
                  loading={loading}
                  name={showOTPInput ? "Sign Up" : "Submit"}
                />
              </div>
              <p style={{display:"flex"}} className="form-group mt-3">
                Existing User?{" "}
                <p
                  onClick={() => {
                    if (searchParams?.get("returnurl")) {
                      navigate("/?returnurl=" + searchParams?.get("returnurl"));
                    } else {
                      navigate("/");
                    }
                  }}
                  style={linkStyle}
                >
                  Sign In
                </p>{" "}
                Here
              </p>
            </div>
          </div>

          <MainModal
            props={signupCredential}
            open={open}
            handleClose={() => {
              clearTimeout(timerS);
              setTimer(0);
              handleClose();
              setSignupCredential((prev) => ({ ...prev, OTPCode: "" }));
              setValue("OTPCode", "");
              setValue("OTP", "");
            }}
            Component={Component}
          />
        </div>
        {loading && (
          <div className="loader-background">
            {" "}
            <div className="centerLoaderLog">
              <Loader />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
const passwordValidation = yup.object({
  password: yup
    .string()
    .matches(
      /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
      "Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters."
    )
    .required("Please enter your password."),
  confirmPassword: yup
    .string()
    .required("Please retype your password.")
    .oneOf([yup.ref("password")], "Password Not Matched"),
});
function Component({ props, handleClose }) {
  const [loading, getRes, setApi] = usePost();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [signupCredential, setSignupCredential] = useState({
    password: "",
    confirmPassword: "",
    OTPCode: props?.OTPCode,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: { ...signupCredential },
    resolver: yupResolver(passwordValidation),
  });
  function handleForm(e) {
    setSignupCredential({
      ...signupCredential,
      [e.target.name]: e.target.value,
    });
  }

  useEffect(() => {
    if (getRes) {
      if (getRes?.Data?.IsSuccessful) {
        toast.success(getRes?.Data?.Message);
        handleClose();
        if (searchParams?.get("returnurl")) {
          navigate("/?returnurl=" + searchParams?.get("returnurl"));
        } else {
          navigate("/");
        }
      } else {
        toast.error(getRes?.Data?.FriendlyMessage || "Something went wrong");
      }
    }
  }, [getRes]);

  const getFormData = (data) => {
    setSignupCredential({
      ...signupCredential,
      password: data?.password,
      confirmPassword: data?.confirmPassword,
    });
    let body = {
      OTPCode: signupCredential.OTPCode,
      password: data.password,
      userid: props.userid,
    };
    setApi(API.registeruser(body));
  };

  const [textBox, setTextBox] = useState({});

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      if (width > 280 && width < 768) {
        setTextBox({
          paddingTop: '3rem',
        })
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Box className='mainBox SetPasswordBox'>
      <div className="text-center" style={textBox}>
        <img
          src={mahospital}
          draggable="false"
          height="auto"
          width="200"
          alt=""
        />
        <h3 className="text-primary" style={{ fontWeight: "900" }}>
          <strong>Set Password</strong>
        </h3>
      </div>
      <div className="ps-5 pe-5 pb-5">
        {/* fullName no */}
        <div className="form-group mt-2">
          <input
            {...register("password")}
            type="password"
            name="password"
            className="form-control p-3"
            aria-describedby="nameHelp"
            placeholder="Enter Password"
          />
          {errors?.["password"] && (
            <p className="errorText">
              {errors?.["password"]?.message?.toString()}
            </p>
          )}
        </div>
        {/* mobile no */}
        <div className="form-group mt-2">
          <input
            {...register("confirmPassword")}
            type="password"
            maxLength="20"
            name="confirmPassword"
            className="form-control p-3"
            aria-describedby="mobileHelp"
            placeholder="Confirm Password"
          />
          {errors["confirmPassword"] && (
            <p className="errorText">
              {errors["confirmPassword"]?.message?.toString()}
            </p>
          )}
        </div>
        {/* checkbox */}
        {/* <div className="form-check mt-3 mb-3">
                <input required type="checkbox" className="form-check-input" id="exampleCheck1" />
                <label className="form-check-label" htmlFor="exampleCheck1">I Agree to the <span> Terms and Condions</span> and <span>Policy</span> </label>
              </div> */}
        {/* submit */}
        <div style={{ display: "inline" }}>
          <LoadingButton
            onClick={handleSubmit(getFormData)}
            loading={loading}
            name={"Sign Up"}
          />
        </div>
      </div>
      {loading && (
        <div className="loader-background">
          {" "}
          <div className="centerLoaderLog">
            <Loader />
          </div>
        </div>
      )}
    </Box>
  );
}

export default SignUp;
