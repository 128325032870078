var API = {
  getdashboarddata: (body) => ({
    url: "api/patientportal/getdashboarddata",
    body: body,
  }),
  getactivehospitals: (body) => ({
    url: "api/patientportal/getactivehospitals",
    body: body,
  }),
  getactivehospitalsLogin: (body) => ({
    url: "api/patientportal/getactivehospitalsLogin",
    body: body,
  }),
  getOtp: (body) => ({ url: "api/patientportal/getandmatchotp", body: body }),
  VerifyAppVersionOTP: () => ({
    url: "api/patientportal/VerifyAppVersion",
    body: {},
  }),
  getRegistrationOtp: (body) => ({
    url: "api/patientportal/getRegistrationOtp",
    body: body,
  }),
  getuhidinfo: (body) => ({
    url: "api/patientportal/getuhidinfo",
    body,
  }),
  generateSequence: (body) => ({
    url: "api/patientportal/generateSequence",
    body,
  }),
  ForgotPasswordGetOTP: (body) => ({
    url: "api/patientportal/getForgotPasswordOtp",
    body,
  }),
  MyReportGetOTP: (body) => ({
    url: "api/patientportal/getMyReportOtp",
    body,
  }),
  MatchMyReportOTP: (body) => ({
    url: "api/patientportal/matchMyReportOtp",
    body,
  }),
  ForgotPasswordMatchOTP: (body) => ({
    url: "api/patientportal/ForgotPasswordMatchOtp",
    body,
  }),
  getNotifications: (body) => ({
    url: "api/patientportal/getalertlist",
    body,
  }),
  updateBadgeCount: (body) => ({
    url: "api/patientportal/updateBadgeCount",
    body,
  }),
  getPaymentHistory: (body) => ({
    url: "api/patientportal/payment/payment-history",
    body,
  }),
  payAdvance: (body) => ({
    url: "api/patientportal/payAdvance",
    body,
  }),
  getPaymentHistoryDetails: (body) => ({
    url: "api/patientportal/payment/payment-history-details",
    body,
  }),
  patientMatchOtp: (body) => ({
    url: "api/patientportal/MatchOtp",
    body: body,
  }),
  matchRegistrationOtp: (body) => ({
    url: "api/patientportal/MatchRegistrationOtp",
    body: body,
  }),
  changePassword: (body) => ({
    url: "api/patientportal/changePassword",
    body,
  }),
  registeruser: (body) => ({
    url: "api/patientportal/registeruser",
    body: body,
  }),
  updatePassword: (body) => ({
    url: "api/patientportal/updatePassword",
    body: body,
  }),
  PatientLogin: (body) => ({
    url: "api/patientportal/patientlogin",
    body: body,
  }),
  getallspecialties: (body) => ({
    url: "api/patientportal/getspecialisationbyhospitalid",
    body: body,
  }),
  A_GetDoctorsListBySpecialityID: (body) => ({
    url: "api/patientportal/getdoctorslistbyspecialityid",
    body: body,
    key: 1,
  }),
  A_getDocsTimming: (body) => ({
    url: "api/patientportal/getdoctorstimings",
    body: body,
    key: 2,
  }),
  saveProfile: (body) => ({
    url: "api/patientportal/saveprofiledata",
    body,
  }),
  A_getdoctorprofile: (body) => ({
    url: "api/patientportal/getdoctorprofile",
    body: body,
    key: 3,
  }),
  A_getdoctorfees: (body) => ({
    url: "api/patientportal/getdoctorfees",
    body: body,
    key: 4,
  }),
  A_getdoctorfeesDetails: (body) => ({
    url: "api/patientportal/getdoctorfeesdetails",
    body: body,
    key: 4,
  }),
  getDoctorFees: (body) => ({
    url: "api/patientportal/getdoctorfees",
    body: body,
  }),
  validateappointment: (body) => ({
    url: "api/patientportal/validateappointment",
    body: body,
  }),
  A_takeappointment: (body) => ({
    url: "api/patientportal/takeappointment",
    body: body,
    key: 5,
  }),
  getuhidlistbymobile: (body) => ({
    url: "api/patientportal/getuhidlistbymobile",
    body: body,
  }),
  getappointmenthistory: (body) => ({
    url: "api/patientportal/getappointmenthistory",
    body: body,
  }),
  registerwithus: (body) => ({
    url: "api/patientportal/registerwithus",
    body: body,
  }),
  getactivestatebycountry: (body) => ({
    url: "api/patientportal/getactivestatebycountry",
    body: body,
  }),
  getactivecitybystate: (body) => ({
    url: "api/patientportal/getactivecitybystate",
    body: body,
  }),
  getActiveAreaByCity: (body) => ({
    url: "api/patientportal/getActiveAreaByCity",
    body: body,
  }),
  getActiveZipCodeByArea: (body) => ({
    url: "api/patientportal/getActiveZipCodeByArea",
    body: body,
  }),
  A_getpreventivehealthcheckups: (body) => ({
    url: "api/patientportal/getpreventivehealthcheckups",
    body: body,
    key: 6,
  }),
  getimmunizationcharturl: (body) => ({
    url: "api/patientportal/getimmunizationcharturl",
    body: body,
  }),
  getipdstatus: (body) => ({
    url: "api/patientportal/getipdstatus",
    body: body,
  }),
  getAdmittedPatients: (body) => ({
    url: "api/patientportal/getadmittedpatients",
    body: body,
  }),
  myreports: (body) => ({ url: "api/patientportal/myreports", body: body }),
  adduserquestions: (body) => ({
    url: "api/patientportal/adduserquestions",
    body: body,
  }),

  payumoney: (body) => ({
    url: "api/patientportal/payment/payumoney",
    body: body,
  }),

  getprofiledata: (body) => ({
    url: "api/patientportal/getprofiledata",
    body: body,
  }),
  getFeedBackOptions: (body) => ({
    url: "api/patientportal/loadfeedback",
    body,
  }),
  postFeedBack: (body) => ({
    url: "api/patientportal/savefeedback",
    body,
  }),
  getAppointmentTypes: (body) => ({
    url: "api/patientportal/getappointmenttypes",
    body,
  }),
  getAppointmentTypesDetails: (body) => ({
    url: "api/patientportal/getappointmenttypesdetails",
    body,
  }),
  getAppointmentDetails: (body) => ({
    url: "api/patientportal/getappointmentdetail",
    body,
  }),
  rescheduleAppointment: (body) => ({
    url: "api/patientportal/rescheduleappointment",
    body,
  }),
  appointmentCancelReason: (body) => ({
    body,
    url: "api/patientportal/getappcancelreason",
  }),
  appointmentCancel: (body) => ({
    body,
    url: "api/patientportal/cancelappointment",
  }),
  getPaymentInfo: (body) => ({
    url: "api/patientportal/getpaymentinfo",
    body,
  }),
  validatePayment: (body) => ({
    url: "api/patientportal/validatepaynow",
    body,
  }),
  getRelatedDoctorList: (body) => ({
    url: "api/patientportal/getDoctorProfileBySpecialisation",
    body,
  }),
  getDcotorProfile: (body) => ({
    url: "api/patientportal/getdoctorprofile",
    body: body,
  }),
  getPaymentGetwayDetails: (body) => ({
    url: "api/patientportal/payment/getPaymentGetwayDetails",
    body: body,
  }),
  isCouponAvailable: (body) => ({
    url: "api/patientportal/payment/isCouponAvailable",
    body: body,
  }),
  getCoupons: (body) => ({
    url: "api/patientportal/payment/getCoupons",
    body: body,
  }),
  validateCoupon: (body) => ({
    url: "api/patientportal/payment/validateCoupon",
    body: body,
  }),
  enableDisableWhatsappNotification: (body) => ({
    url: "api/patientportal/enableDisableWhatsappNotification",
    body,
  }),
  payLater: (body) => ({
    url: "api/patientportal/payLater",
    body,
  }),
  uploadPrescription: (body) => ({
    url: "api/patientportal/uploadPrescription",
    body,
  }),
  getZipCodeDetails: (body) => ({
    url: "api/patientportal/zipCodeDetails",
    body,
  }),
  getDocumentsBasedOnFacility: (body) => ({
    url: "api/patientportal/getDocumentsBasedOnFacility",
    body,
  }),
};

export default API;
