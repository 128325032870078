import React from "react";
import { cardOrientation } from "assets/util/common/root";
import { callGet } from "common/local";
import { POLICY_BASE_URL } from "server/server";
import { refundPolicy } from "util/policy_content";

const RefundPolicy = () => {
  const userData = callGet("auth");
  // console.log(userData);
  return (
    <div
      className="patient_header mt-3 scroll-bar"
      style={{ ...cardOrientation }}
    >
      {/* <iframe
        className="privacy-policy"
        title={"Refund policy page"}
        src={POLICY_BASE_URL + userData?.policyLinks?.RefundPolicyFileName}
      ></iframe> */}
      <div dangerouslySetInnerHTML={{ __html: refundPolicy }}></div>
    </div>
  );
};

export default RefundPolicy;
