import React, { useEffect, useState } from "react";
import { cardOrientation } from "../../assets/util/common/cardOrientation";
import "./navbar.css";
import AmbulanceIcon from "./navbarIcons/AmbulanceIcon";
import NotificationIcon from "./navbarIcons/NotificationIcon";
import dummyProfile from "../../assets/image/profile.png";
import { useDispatch, useSelector } from "react-redux";
import { NavDropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import RootSearch from "../../assets/util/Root/RootSearch/RootSearch";
import useLogOut from "../../Hooks/useLogout";
import actions from "Action/action";
import { Icon } from "@iconify/react";
import { callGet, callSet, delLocal } from "common/local";
import API from "util/API/api";
import Modal from "@mui/material/Modal";
import { postAPIData } from "Hooks/Services";
import { toast } from "react-hot-toast";
import ContactUs from "./ContactUs";
import Notifications from "./Notifications";
import Drawer from "@mui/material/Drawer";
import Sidebar from "main/sideBar/Sidebar";
import { firstCap } from "Pages/Patientregister/utils";
var isSearchBar = [
  "book-appointment",
  // "doctList",
  // "view-profile",
  // "appointDoc",
  // "patientName",
  // "payment",
  "preventive",
  "specialties",
  // "Immunisation",
];

function Navbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [currentModal, setCurrentModal] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, callLogOut] = useLogOut();
  const [hospitalOptions, setHospitalOptions] = useState([]);
  const [url, setUrl] = useState("");
  const localAuthData = callGet("auth");
  const [badgeCount, setBadgeCount] = useState(0);
  const [selectedHospital, setSelectedHospital] = useState(
    localAuthData?.hospitalId
  );
  const [showSidebar, setShowSidebar] = useState(false);
  const [searchData, setsearchData] = useState("");
  const handleClose = () => {
    setOpenModal(false);
    setCurrentModal("");
  };
  const navBarStyle = {
    ...cardOrientation,
    minHeight: "70px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
  };
  const withOutSBar = {
    ...cardOrientation,
    minHeight: "70px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
  };
  const userCredential = useSelector((state) => state.R_getPatientLogin);

  useEffect(() => {
    setUrl(window.location.href.split("/").at(-1));
    if (status) navigate("/");
    dispatch(
      actions.A_searchText({
        text: "",
        hospitalId: callGet("auth")?.hospitalId,
      })
    );
    setSelectedHospital(callGet("auth")?.selectedHospitalId);
    setsearchData("");
  }, [status, window.location.href]);
  const getHospitals = async (data) => {
    try {
      setLoading(true);
      const resp_ActiveHospitals = await postAPIData({
        ...API.getactivehospitals(data),
      });
      if (!resp_ActiveHospitals?.data?.IsSuccessful) {
        toast.error(
          resp_ActiveHospitals?.data?.FriendlyMessage || "Something went wrong"
        );
        return;
      }
      setHospitalOptions(
        resp_ActiveHospitals?.data?.Data[0]?.Hospitals.map((e) => ({
          name: e?.FacilityName,
          value: e?.FacilityId,
        }))
      );
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    var data = { ...callGet("auth") };
    getHospitals(data);
  }, []);
  return (
    <React.Fragment >
      <div style={{ position: "relative" }}>
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <React.Fragment>
            {currentModal === "contactUs" && (
              <div className="contact-us-modal">
                {currentModal === "contactUs" && (
                  <ContactUs setClose={setOpenModal} />
                )}
              </div>
            )}
            {/* {currentModal === "changePassword" && (
              <div className="change-password-modal">
                <ChangePassword setClose={setOpenModal} />
              </div>
            )} */}
            {currentModal == "notification" && (
              <div className="modalAlingmentNotification">
                {currentModal === "notification" && (
                  <Notifications
                    badgeCount={badgeCount}
                    setBadgeCount={setBadgeCount}
                  />
                )}
              </div>
            )}
          </React.Fragment>
        </Modal>
      </div>
      <div style={isSearchBar?.includes(url) ? navBarStyle : withOutSBar} className={isSearchBar ? 'NewNavClass' : ''}>
        <div className="show-sidebar">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => setShowSidebar(true)}
          >
            <Icon icon="heroicons:bars-3-20-solid" fontSize={30} />
          </div>
          {showSidebar && (
            <button
              onClick={() => {
                setShowSidebar(false);
              }}
              className="cross-button"
            >
              X
            </button>
          )}
          <Drawer
            anchor="left"
            open={showSidebar}
            onClose={() => setShowSidebar(false)}
          >
            <Sidebar
              showSidebar={showSidebar}
              setShowSidebar={setShowSidebar}
            />
          </Drawer>
        </div>
        <div className="mainenavbar">
          {isSearchBar.includes(url) ? (
            <React.Fragment>
              <div className="mainnavbar-left d-none d-sm-block">
                {url !== "preventive" && (
                  <RootSearch
                    placeholder={"Search By Doctor/Speciality"}
                    setsearchData={setsearchData}
                    onChange={(e) => {
                      setsearchData(e?.target.value);
                      dispatch(
                        actions.A_searchText({
                          text: e?.target?.value,
                          hospitalId: selectedHospital,
                        })
                      );
                    }}
                    searchData={searchData}
                    borderRadius={{ outLine: "1px solid red" }}
                  />
                )}
              </div>
              <div className="mainnavbar-center d-none d-sm-block">
                <select
                  id="rootField"
                  value={selectedHospital}
                  className="form-select borderradius mainselectdropdown"
                  aria-label="Default select example"
                  onChange={(e) => {
                    callSet({
                      name: "auth",
                      data: {
                        ...localAuthData,
                        selectedHospitalId: e?.target?.value,
                      },
                    });
                    setSelectedHospital(e?.target?.value);
                    dispatch(
                      actions.A_searchText({
                        text: searchData,
                        hospitalId: e?.target?.value,
                      })
                    );
                  }}
                >
                  {!hospitalOptions?.length && (
                    <option value={""} disabled selected>Loading...</option>
                  )}
                  {hospitalOptions?.length && (
                    <option value={""} disabled selected>Select Hospital</option>
                  )}
                  {hospitalOptions?.length &&
                    hospitalOptions?.map((e, i) => {
                      return (
                        <option key={i} value={e?.value}>
                          {e?.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </React.Fragment>
          ) : (
            <div className="mainnavbar-left"></div>
          )}
          <div className="mainnavbar-right">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => navigate("Ambulance")}
              className="me-3"
            >
              <AmbulanceIcon />
            </div>
            <div
              onClick={() => {
                setOpenModal(true);
                setCurrentModal("notification");
              }}
              className="me-3 vertical-line"
            >
              <NotificationIcon
                badgeCount={badgeCount}
                setBadgeCount={setBadgeCount}
                text={currentModal}
              />
            </div>
            <NavDropdown
              title={
                <img
                  draggable="false"
                  src={dummyProfile}
                  width={30}
                  alt="profile-pic"
                />
              }
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item onClick={() => navigate("MyProfile")}>
                <Icon
                  className="icons-pic"
                  fontSize={15}
                  icon="iconamoon:profile-fill"
                  color="gray"
                />{" "}
                Profile
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                onClick={() => {
                  navigate("contact-us");
                  setCurrentModal("contactUs");
                }}
              >
                <Icon
                  className="icons-pic"
                  fontSize={17}
                  icon="fluent:location-20-regular"
                  color="gray"
                />{" "}
                Contact Us
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                onClick={() => {
                  navigate("change-password");
                  // setOpenModal(true);
                  setCurrentModal("changePassword");
                }}
              >
                <Icon
                  className="icons-pic"
                  fontSize={15}
                  icon="teenyicons:key-solid"
                  color="gray"
                  rotate={3}
                />{" "}
                Change Password
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => navigate("paymentHistory")}>
                <Icon
                  className="icons-pic"
                  fontSize={15}
                  icon="mdi:recurring-payment"
                  color="gray"
                />{" "}
                Payment History
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => navigate("myAppointment")}>
                <Icon
                  className="icons-pic"
                  fontSize={13}
                  icon="teenyicons:appointments-solid"
                  color="gray"
                />{" "}
                My Appointments
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => {
                dispatch({ type: "USER_LOGOUT" });
                delLocal("auth");
                navigate('/')
              }}>
                <Icon
                  className="icons-pic"
                  fontSize={15}
                  icon="teenyicons:logout-solid"
                  color="blue"
                />{" "}
                Logout
              </NavDropdown.Item>
            </NavDropdown>
            <div className="profiledetails">
              <h4>Welcome</h4>
              <p>{firstCap(userCredential?.FullName) || "User"}</p>
            </div>
          </div>
        </div>
        <br />
        {/* For mobile */}
        {isSearchBar.includes(url) && (
          <React.Fragment>
            <div className="col-12 searchbydoctor d-block d-sm-none p-2">
              {url !== "preventive" && (
                <RootSearch
                  placeholder={"Search By Doctor/Speciality"}
                  setsearchData={setsearchData}
                  onChange={(e) => {
                    setsearchData(e?.target.value);
                    dispatch(
                      actions.A_searchText({
                        text: e?.target?.value,
                        hospitalId: selectedHospital,
                      })
                    );
                  }}
                  searchData={searchData}
                  borderRadius={{ outLine: "1px solid red" }}
                />
              )}
            </div>
            <div className={`col-12 headersdropdown d-block d-sm-none p-2`}>
              <select
                id="rootField"
                value={selectedHospital}
                className="form-select borderradius"
                aria-label="Default select example"
                onChange={(e) => {
                  callSet({
                    name: "auth",
                    data: {
                      ...localAuthData,
                      selectedHospitalId: e?.target?.value,
                    },
                  });
                  setSelectedHospital(e?.target?.value);
                  dispatch(
                    actions.A_searchText({
                      text: searchData,
                      hospitalId: e?.target?.value,
                    })
                  );
                }}
              >
                {!hospitalOptions?.length && (
                  <option value={""} disabled selected>Loading...</option>
                )}
                {hospitalOptions?.length && (
                  <option value={""} disabled selected>Select Hospital</option>
                )}
                {hospitalOptions?.length &&
                  hospitalOptions?.map((e, i) => {
                    return (
                      <option key={i} value={e?.value}>
                        {e?.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
}

export default Navbar;
