import React, { useEffect, useState } from "react";
import "./signIn.css";
import mahospital from "../assets/image/mahospitalmain.png";
import { linkStyle } from "../assets/util/common/linkStyle";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import MainIcon from "../assets/mainIcon/MainIcon";
import API from "util/API/api";
import { useDispatch } from "react-redux";
import actions from "Action/action";
import { callGet, callSet } from "../common/local";
import { LoadingButton } from "../assets/loading/LoadingButton";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getAPIData, postAPIData } from "Hooks/Services";
import Loader from "Pages/Loader";
const schema = yup.object().shape({
  Password: yup.string().required("Password is required"),
  HospitalId: yup.string().required("This field is required"),
  userid: yup
    .string()
    .required("Number is required")
    .min(10, "Phone number is not valid")
    .matches(/(0|91)?[6-9][0-9]{9}/, "Phone number is not valid")
    .test("numberCheck", "Phone number is not valid", (val) =>
      val?.toString()?.startsWith(0)
        ? val?.length == 11
        : val.toString()?.startsWith(91)
        ? val?.length == 12
        : val?.length == 10
    ),
  checkbox: yup
    .bool()
    .required("This field is required")
    .test("check", "This field is required", (val) => val === true),
});

const dummyClientId =
  "dRZvBSEiQpqOBA_cHLsOZ8:APA91bH5Y7So2GnGKE2zohgmzevTYYT8oJsv40_hpsFoA4WYjRk3t4LYuGnz14_4MiIKTvXk2UR9cfJ0YDJYaWvZlPHoqVJRS1KVFqPtK_yrQ5pT7tYU791Tq_64ohut2NMYiPfz407O";

function SignIn() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [policyLoading, setPolicyLoading] = useState(false);
  const authData = callGet("auth");
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [hospitalOptions, setHospitalOptions] = useState([]);
  const [loginCredentian, setLoginCredential] = useState({
    userid: "",
    Password: "",
    OTPCode: "",
    HospitalId: "",
    checkbox: false,
  });
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "onSubmit",
    defaultValues: { ...loginCredentian },
    resolver: yupResolver(schema),
  });
  const getPolicyResp = async (data) => {
    try {
      setPolicyLoading(true);
      const policyResp = await postAPIData({
        ...API?.getDocumentsBasedOnFacility({
          FacilityId: data,
          userId: "",
        }),
      });
      if (policyResp?.data?.length) {
        callSet({
          name: "auth",
          data: {
            policyLinks: policyResp?.data?.[0],
          },
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setPolicyLoading(false);
    }
  };
  const setData = (data, FacilityInfo, policyResp) => {
    if (data?.IsSuccessful) {
      dispatch(actions.getPatientLogin(data?.Data[0]));
      callSet({
        name: "auth",
        data: {
          ClientId: data?.ClientId ? data?.ClientId : dummyClientId,
          ClientToken: data?.ClientToken,
          userId: loginCredentian?.userid,
          UHID: data?.Data?.[0]?.UHIDs[0]?.UHID,
          hospitalId: data?.Data[0]?.HospitalId,
          selectedHospitalId: data?.Data?.[0]?.HospitalId,
          hospitalName:
            hospitalOptions?.find(
              (e) => e?.value == data?.Data?.[0]?.HospitalId
            )?.name || "",
          FacilityInfo: FacilityInfo?.[0],
          policyLinks: policyResp,
        },
      });
      if (searchParams?.get("returnurl")) {
        navigate("/dashboard", { replace: true });
        navigate("/" + searchParams?.get("returnurl"));
      } else {
        navigate("/dashboard");
      }
    } else {
      toast.error(data?.FriendlyMessage || "Something went wrong");
    }
  };
  const SignIn = async (data) => {
    try {
      setLoading(true);
      const resp = await postAPIData({ ...API.PatientLogin(data) });
      if (!resp?.data?.data?.IsSuccessful) throw resp?.data?.data;
      const policyResp = await postAPIData({
        ...API?.getDocumentsBasedOnFacility({
          FacilityId: data?.HospitalId,
          userId: data?.userid,
          ClientId: resp?.data?.data?.Data?.[0]?.ClientId || "",
          ClientToken: resp?.data?.data?.Data?.[0]?.ClientToken || "",
        }),
      });
      const dashBoardResp = await postAPIData({
        ...API.getdashboarddata({
          FacilityId: data?.HospitalId,
          userId: data?.userid,
          ClientId: resp?.data?.data?.Data?.[0]?.ClientId || "",
          ClientToken: resp?.data?.data?.Data?.[0]?.ClientToken || "",
        }),
      });
      setData(
        resp?.data?.data,
        dashBoardResp?.data?.Data?.[0]?.FacilityInfo,
        policyResp?.data?.[0]
      );
    } catch (err) {
      toast.error(err?.FriendlyMessage || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };
  const getFormData = (data) => {
    setLoginCredential((e) => data);
    SignIn(data);
  };
  const getHospitals = async (data) => {
    try {
      setLoading(true);
      const resp = await getAPIData({
        url: "/api/patientportal/getactivehospitalsLogin",
      });
      const hospitalsOptions = resp?.data?.[0]?.Hospitals?.map((e) => ({
        name: e?.FacilityName,
        value: e?.FacilityId,
      }));
      let curr_hospital = hospitalsOptions?.find((e) => e?.value == data?.id);
      // console.log(curr_hospital, data?.id)
      if (curr_hospital?.value) {
        setValue("HospitalId", curr_hospital?.value);
        setLoginCredential({
          ...loginCredentian,
          HospitalId: curr_hospital?.value,
        });
        getPolicyResp(curr_hospital?.value);
      }
      setHospitalOptions(hospitalsOptions);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (authData?.ClientToken) {
      if (searchParams?.get("returnurl")) {
        navigate("/dashboard", { replace: true });
        navigate("/" + searchParams?.get("returnurl"));
      } else {
        navigate("/dashboard");
      }
    } else {
      localStorage.clear();
      getHospitals({ id: searchParams?.get("returnurl")?.split(",")?.[1] });
    }
  }, [searchParams?.get("returnurl")]);
  // console.log(searchParams?.get("returnurl"));
  return (
    <React.Fragment>
      <div
        className="container-fluid rootBackground d-flex justify-content-center align-items-center"
        style={{ minHeight: "80vh", padding: "50px 0px" }}
      >
        <MainIcon top={0} left={10} width={350} />
        <div className="signInBox shadow-lg bg-light ">
          <div className="text-center">
            <img
              src={mahospital}
              draggable="false"
              height="auto"
              width="200"
              style={{ padding: "50px 0" }}
              alt="Marengo Asia Hospital Logo"
            />
            <h3 className="text-primary" style={{ fontWeight: "900" }}>
              <strong>LogIn as Patient</strong>
            </h3>
          </div>
          <div className="ps-5 pe-5 pb-5">
            <div className="form-group pb-0">
              <input
                {...register("userid", {
                  onChange: (e) => {
                    if (e?.target?.value?.length > 10) {
                      setValue("userid", loginCredentian?.userid);
                      return;
                    }
                    setLoginCredential({
                      ...loginCredentian,
                      userid: e.target.value,
                    });
                  },
                })}
                autoFocus
                title="This field should not be left blank."
                data-errormessage-value-missing="Please input something"
                name="userid"
                type="number"
                className="form-control pb-1"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter Mobile Number*"
              />
              {errors?.["userid"] && (
                <p className="errorText">
                  {errors?.["userid"]?.message?.toString()}
                </p>
              )}
            </div>
            {/* password input */}
            <div className="form-group pb-1">
              <input
                {...register("Password", {
                  onChange: (e) => {
                    setLoginCredential({
                      ...loginCredentian,
                      Password: e.target.value,
                    });
                  },
                })}
                name="Password"
                type="Password"
                className="form-control pb-1"
                id="exampleInputPassword1"
                aria-describedby="emailHelp"
                placeholder="Enter Password*"
              />
              {errors?.["Password"] && (
                <p className="errorText">
                  {errors?.["Password"]?.message?.toString()}
                </p>
              )}
            </div>
            {/* select */}
            <div className="form-group pb-0">
              <select
                {...register("HospitalId", {
                  onChange: (e) => {
                    setLoginCredential({
                      ...loginCredentian,
                      HospitalId: e.target.value,
                    });
                    getPolicyResp(e.target.value);
                  },
                })}
                value={getValues("HospitalId") || ""}
                required
                name="HospitalId"
                className="form-control pb-1"
                id="exampleFormControlSelect1"
              >
                {!hospitalOptions?.length && (
                  <option value={""} disabled selected>
                    Loading...
                  </option>
                )}
                {hospitalOptions?.length > 0 && (
                  <option value="" disabled selected>
                    Select Hospital
                  </option>
                )}
                {hospitalOptions?.length > 0 &&
                  hospitalOptions?.map((e, i) => {
                    return (
                      <option key={i} value={e?.value}>
                        {e?.name}
                      </option>
                    );
                  })}
              </select>
              {errors?.["HospitalId"] && (
                <p className="errorText">
                  {errors?.["HospitalId"]?.message?.toString()}
                </p>
              )}
            </div>
            <div style={{paddingTop:"20px"}} className="form-check mt-3 mb-3">
              <input
                {...register("checkbox")}
                type="checkbox"
                name="checkbox"
                style={{ cursor: "pointer" }}
                className="form-check-input"
                // id="exampleCheck1"
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                I Agree to the{" "}
                <span
                  onClick={() => {
                    navigate("/termsConditions");
                  }}
                >
                  {" "}
                  Terms and Conditions
                </span>
                ,{" "}
                <span
                  onClick={() => {
                    navigate("/privacyPolicy");
                  }}
                >
                  Privacy Policy
                </span>{" "}
                and compliance towards{" "}
                <span
                  onClick={() => {
                    navigate("/dpdp-act");
                  }}
                >
                  {" "}
                  DPDP Act
                </span>
              </label>
              {errors?.["checkbox"] && (
                <p className="errorText">
                  {errors?.["checkbox"]?.message?.toString() || ""}
                </p>
              )}
            </div>
            <div style={{ display: "inline" }}>
              <LoadingButton style={{ marginBottom: "1rem" }}
                onClick={handleSubmit(getFormData)}
                loading={loading}
                name={"Login"}
              />
            </div>
            <p
              onClick={() => navigate("/forgot-password")}
              style={{ ...linkStyle, width: "fit-content", marginBottom: '0' }}
            >
              Forgot Your Password?
            </p>
            <p style={{height:"50px",zIndex:"999999",display:"flex"}} className="form-group mt-3">
              New User?{" "}
              <p
                onClick={() => {
                  if (searchParams?.get("returnurl")) {
                    navigate(
                      "/SignUp?returnurl=" + searchParams?.get("returnurl")
                    );
                  } else {
                    navigate("/SignUp");
                  }
                }}
                style={linkStyle}
              >
                Sign Up
              </p>{" "}
              Here
            </p>
          </div>
        </div>
      </div>
      {loading ||
        (policyLoading && (
          <div className="loader-background">
            <div className="centerLoaderLog">
              <Loader />
            </div>
          </div>
        ))}
    </React.Fragment>
  );
}

export default SignIn;
