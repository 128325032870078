import { postAPIData } from "Hooks/Services";
import Loader from "Pages/Loader";
import RootSearch from "assets/util/Root/RootSearch/RootSearch";
import { cardOrientation } from "assets/util/common/root";
import { callGet } from "common/local";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import API from "util/API/api";
const paraStyle = { fontSize: ".9rem", fontWeight: "500" };
const PaymentHistory = () => {
  const [searchData, setSearchData] = useState("");
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [FriendlyMessage, setFriendlyMessage] = useState("");
  const [paymentData, setPaymentData] = useState([]);
  const navigate = useNavigate();
  const getPaymentHistory = async (data) => {
    try {
      setLoading(true);
      var paymentHistoryResp = await postAPIData({
        ...API.getPaymentHistory(data),
      });
      paymentHistoryResp = paymentHistoryResp?.data;
      if (paymentHistoryResp?.IsSuccessful) {
        setPaymentData(paymentHistoryResp?.Data);
        setFilterData(paymentHistoryResp?.Data);
      } else {
        // toast.error(
        //   paymentHistoryResp?.FriendlyMessage || "Soemthing went wrong"
        // );
        setFriendlyMessage(paymentHistoryResp?.FriendlyMessage);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const authData = callGet("auth");
    getPaymentHistory(authData);
  }, []);
  return (
    <div>
      {" "}
      <div className="--header mt-3 p-5" style={{ ...cardOrientation }}>
        <h4>Payment History</h4>
        <div className="pt-2 col-sm-6">
          <RootSearch
            placeholder={"UHID/Patient"}
            setsearchData={setSearchData}
            searchData={searchData}
            onChange={(a) => {
              setSearchData(a?.target?.value);
              let text = a?.target?.value?.trim()?.toLowerCase();
              if (text) {
                let Arr = paymentData?.filter(
                  (e) =>
                    e?.UHID?.trim()?.includes(text) ||
                    e?.PatientName?.trim()?.toLowerCase()?.includes(text)
                );
                setFilterData(Arr);
              } else {
                setFilterData(paymentData);
              }
            }}
            disabled={loading || !paymentData?.length}
            borderRadius={{ outLine: "1px solid red" }}
          />
        </div>
        <div
          className="container-fluid"
          style={{
            ...cardOrientation,
            backgroundColor: "transparent",
            minHeight: "50vh",
            maxHeight: "120vh",
            overflowY: "auto",
          }}
        >
          {loading ? (
            <div className="centerLoader1">
              <Loader />
            </div>
          ) : (
            <div
              className="row mt-4"
              style={{ cursor: "pointer" }}
            >
              {!loading ? (
                filterData?.length > 0 ? (
                  filterData?.map((e, i) => (
                    <div
                      className="col-md-6 col-xl-4"
                      style={{ padding: "0" }}
                      key={i}
                      onClick={() => {
                        navigate(
                          `/dashboard/paymentHistoryDetails?paymentId=${e?.Id}&transactionId=${e?.TransactionID}`
                        );
                      }}
                    >
                      <div
                        className="card pt-2 mb-2 px-3 "
                        style={{
                          border: "1px solid #007DC6",
                          minHeight: "20rem",
                          maxHeight: "20rem",
                          background: "#FFFFFF",
                        }}
                      >
                        <div className="card-body">
                          <table className="mt-3" style={{ width: "auto" }}>
                            <tr style={{ lineHeight: "30px" }}>
                              <td
                                style={{ color: "#007DC6", fontWeight: "500" }}
                              >
                                Receipt No.:
                              </td>
                              <td style={{ fontWeight: "500" }}>
                                {" "}
                                {e?.InvoiceNumber || "----"}
                              </td>
                            </tr>
                            <tr style={{ lineHeight: "30px" }}>
                              <td
                                style={{ color: "#007DC6", fontWeight: "500" }}
                              >
                                UHID:
                              </td>
                              <td style={{ fontWeight: "500" }}>
                                {" "}
                                {e?.UHID || "----"}
                              </td>
                            </tr>
                            <tr style={{ lineHeight: "30px" }}>
                              <td
                                style={{ color: "#007DC6", fontWeight: "500" }}
                              >
                                Patient Name:
                              </td>
                              <td style={{ fontWeight: "500" }}>
                                {" "}
                                {e?.PatientName || "----"}
                              </td>
                            </tr>
                            <tr style={{ lineHeight: "30px" }}>
                              <td
                                style={{ color: "#007DC6", fontWeight: "500" }}
                              >
                                Transaction On:
                              </td>
                              <td style={{ fontWeight: "500" }}>
                                {" "}
                                {e?.TransactionOn || "----"}
                              </td>
                            </tr>
                            <tr style={{ lineHeight: "30px" }}>
                              <td
                                style={{ color: "#007DC6", fontWeight: "500" }}
                              >
                                Type:
                              </td>
                              <td style={{ fontWeight: "500" }}>
                                {" "}
                                {e?.Title || "----"}
                              </td>
                            </tr>
                            <tr style={{ lineHeight: "30px" }}>
                              <td
                                style={{ color: "#007DC6", fontWeight: "500" }}
                              >
                                Amount:
                              </td>
                              <td style={{ fontWeight: "500" }}>
                                {" "}
                                {e?.Amount ? "₹" + e?.Amount : "----"}
                              </td>
                            </tr>
                          </table>
                          {/* <h5
                            style={{
                              color: "#007DC6",
                              ...paraStyle,
                              fontSize: "1.2rem",
                            }}
                          >
                            {e?.DoctorName || ""}
                          </h5> */}
                          {/* <p style={paraStyle}>
                            Hospital Name: {e?.HospitalName}
                          </p> */}
                          {/* <p style={paraStyle}>
                            Transaction On: {e?.TransactionOn}
                          </p> */}
                          {/* <p style={paraStyle}>Type: {e?.Title} </p>
                          <p style={paraStyle}>Amount: {e?.Amount} </p> */}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-center">No Data Found</p>
                )
              ) : (
                <p className="text-center">{FriendlyMessage}</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentHistory;
