import React, { useEffect, useState } from "react";
import { cardOrientation } from "assets/util/common/root";
import dummyProfile from "assets/image/profile.png";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import { postAPIData } from "Hooks/Services";
import API from "util/API/api";
import { callGet } from "common/local";
import { toast } from "react-hot-toast";
const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: green[600],
    "&:hover": {
      backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: green[600],
  },
}));
const PatientProfile = ({ data, setEditPage }) => {
  const [loading, setLoading] = useState(false);
  const [isEnabled, setIsEnabled] = useState(
    data?.IsWhatsAppNotifiactionEnable
  );
  const authData = callGet("auth");
  const whatsappNotification = async (data) => {
    try {
      setLoading(true);
      const resp = await postAPIData({
        ...API.enableDisableWhatsappNotification({
          ...data,
        }),
      });
      if (resp?.data?.IsSuccessful) {
        toast.success(resp?.data?.FriendlyMessage);
      } else {
        setIsEnabled(data?.IsWhatsAppNotifiactionEnable);
        toast.error(resp?.data?.FriendlyMessage || "Something went wrong");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    setIsEnabled(data?.IsWhatsAppNotifiactionEnable);
  }, [data?.IsWhatsAppNotifiactionEnable]);
  return (
    <div>
      <div
        onClick={() => {
          setEditPage(true);
        }}
        style={{ cursor: "pointer" }}
      >
        Edit Profile
      </div>
      <div>
        <img
          style={{
            width: "150px",
            borderRadius: "50%",
          }}
          src={
            data?.ProfilePic
              ? `https://patientapp.marengoasia.com/Files/User/` +
                data?.ProfilePic
              : dummyProfile
          }
          alt="profile pic"
        />
      </div>
      <div>
        <p>Welcome {data?.FullName || "User"}</p>
        <b>{data?.FacilityInfo?.[0]?.FacilityName || ""}</b>
      </div>
      <div style={{ color: "green" }}>
        <b>Whatsapp Notification</b>
        <GreenSwitch
          checked={isEnabled}
          disabled={loading}
          onChange={(e) => {
            setIsEnabled(e?.target?.checked);
            whatsappNotification({
              ...authData,
              IsWhatsAppNotifiactionEnable: e?.target?.checked,
            });
          }}
        />
      </div>
    </div>
  );
};

export default PatientProfile;
