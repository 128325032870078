import React, { useEffect, useState } from "react";
import MainIcon from "assets/mainIcon/MainIcon";
import { rootButton } from "assets/util/common/button";
import { cardOrientation } from "assets/util/common/cardOrientation";
import { linkStyle } from "assets/util/common/linkStyle";
import "./paymentsummary.css";
import { useDispatch, useSelector } from "react-redux";
import CryptoJS from "crypto-js";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import { SERVER } from "server/server";
import { callGet, callSet } from "common/local";
import { toast } from "react-hot-toast";
import { postAPIData } from "Hooks/Services";
import API from "util/API/api";
import Loader from "Pages/Loader";
import Cookies from "js-cookie";
import { secretKey } from "Pages/Appointment/DoctorList/ApppintmentTime/PatientName/PatientPayment/PatientPayment";
const CollectionType = {
  1: "OPD Collection",
  2: "IPD Collection",
};
function PayementSummary() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  var myDataCookie = searchParams?.get("payment") || "";
  const authData = callGet("auth");
  const [trxnData, setTrxnData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [gatewayLoading, setGatewayLoading] = useState(false);
  const [advLoading, setAdvLoading] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [merchantData, setMerchantData] = useState({});
  const paymentDetails = useSelector((state) => state?.R_paymentDetails);
  const rootColor = { color: "#FF6F0F", cursor: "pointer", cursor: "default" };
  const mainDiv = { ...cardOrientation, position: "relative" };
  useEffect(() => {
    (() => {
      myDataCookie = myDataCookie?.trim()?.replaceAll(" ", "+");
      if (myDataCookie) {
        const bytes = CryptoJS.AES.decrypt(myDataCookie, secretKey);
        let decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        decryptedData = JSON.parse(decryptedData);
        if (decryptedData?.status !== "success") {
          toast.error("Payment unsuccessful");
          return;
        }
        const payload = {
          Amount: paymentDetails?.amount,
          ServiceId: "0",
          ServiceType: "AP",
          AdvanceTypeId: "1",
          AppointmentDate: "",
          AppointmentId: "0",
          CouponId: "0",
          DiscountAmount: "0.0",
          DoctorId: "0",
          EmailId: paymentDetails?.EmailId,
          HospitalId: paymentDetails?.hospitaName,
          IsCancelledByUser: false,
          MobileNumber: paymentDetails?.MobileNumber,
          Mode: decryptedData?.mode,
          PaymentResponse: decryptedData,
          RescheduleTimeSlot: "",
          Status: decryptedData?.status === "success",
          TimeSlot: "",
          TimeSlotId: "0",
          TransactionId: decryptedData?.txnid,
          UHID: paymentDetails?.UHID,
          additionalCharges: decryptedData?.additionalCharges,
          MihPayId: decryptedData?.mihpayid,
          txnId: authData?.TxnID,
          authData,
          paymentData: [
            {
              ServiceId: "0",
              ServiceType: "AP",
              Amount: paymentDetails?.amount,
            },
          ],
        };
        payAdvance(payload);
      }
    })();
  }, [params, myDataCookie]);
  const callPayment = async () => {
    try {
      setPaymentLoading(true);
      var body = {
        UHID: paymentDetails?.UHID,
        AgreeWithTerms: "true",
        ClientId: authData?.ClientId,
        ClientToken: authData?.ClientToken,
        userId: authData?.userId,
        name: paymentDetails?.Name,
        email: paymentDetails?.EmailId,
        phone: paymentDetails?.MobileNumber,
        amount: paymentDetails?.amount,
        MerchantKey: merchantData?.MerchantKey || "",
        Salt: merchantData?.Salt || "",
        txnId: authData?.TxnID,
      };
      dispatch({ type: "GET_TAKE_APPOINT", payload: body });
      const paymentResp = await axios.post(
        `${SERVER}/api/patientportal/online-payment/payumoney`,
        body
      );
      setTrxnData(paymentResp?.data?.info);
      dispatch({ type: "SET_HASH", payload: paymentResp.data.info });
      let htmlBody = `<html>
             <body>
             <form name="payment_post" id="payu_form" action="${paymentResp.data.info.payu_url}" method="post">
             <input type='hidden' name='firstname' value="${paymentResp.data.info.first_name}"/>
             <input type='hidden' name='email' value="${paymentResp.data.info.email}"/>
             <input type='hidden' name='phone' value="${paymentResp.data.info.mobile}"/>
             <input type='hidden' name='surl' value="${paymentResp.data.info.callback_url}"/>
             <input type='hidden' name='curl' value="${paymentResp.data.info.payu_cancel_url}"/>
             <input type='hidden' name='furl' value="${paymentResp.data.info.payu_fail_url}"/>
             <input type='hidden' name='key' value="${paymentResp.data.info.payu_merchant_key}"/>
             <input type='hidden' name='hash' value="${paymentResp.data.info.payu_sha_token}"/>
             <input type='hidden' name='txnid' value="${paymentResp.data.info.txnId}"/>
             <input type='hidden' name='productinfo' value="${paymentResp.data.info.plane_name}"/>
             <input type='hidden' name='amount' value="${paymentResp.data.info.amount}"/>
             <input type='hidden' name='service_provider' value="${paymentResp.data.info.service_provider}"/>
             </form>
             <script type='text/javascript'>
                  document.getElementById("payu_form").submit();
             </script>
             </body>
         </html>`;

      const winURL = URL.createObjectURL(
        new Blob([htmlBody], { type: "text/html" })
      );
      window.location.href = winURL;
    } catch (err) {
      console.error(err);
    } finally {
      setPaymentLoading(false);
    }
  };
  const payAdvance = async (data) => {
    try {
      setAdvLoading(true);
      const resp = await postAPIData({
        ...API.payAdvance(data),
      });
      if (!resp?.data?.IsSuccessful) {
        throw resp?.data;
      }
      Cookies.remove("myData");
      searchParams?.delete("payment");
      setSearchParams(searchParams);
      dispatch({ type: "CLEAR_TAKE_APPOINT" });
      navigate("/dashboard", { replace: true });
      navigate(`/dashboard/redirects/payment?type=payment&success=true`);
    } catch (err) {
      console.error(err);
      toast.error(err.FriendlyMessage || "Something went wrong");
    } finally {
      setAdvLoading(false);
    }
  };
  const gateWayDetails = async (data) => {
    try {
      setGatewayLoading(true);
      const resp = await postAPIData({
        ...API.getPaymentGetwayDetails({
          FacilityId: data,
          ...authData,
        }),
      });
      if (resp?.data?.IsSuccessful) {
        setMerchantData(resp?.data?.Data);
      } else {
        throw resp?.data;
      }
    } catch (err) {
      console.error(err);
    } finally {
      setGatewayLoading(false);
    }
  };
  useEffect(() => {
    if (paymentDetails?.hospitaName) {
      gateWayDetails(paymentDetails?.hospitaName);
    }
  }, [paymentDetails?.hospitaName]);
  return (
    <div className="container-fluid p-5 mt-2 payment-summary" style={mainDiv}>
      {(loading || advLoading || gatewayLoading || paymentLoading) && (
        <div className="centerLoader2">
          <Loader />
        </div>
      )}
      <MainIcon bottom={15} right={20} width={350} />
      <h4 className="payment-heading"> Payment Summary </h4>
      <div className="pateinet-detail mt-3">
        <h4>Patient Detail</h4>
        <table className="mt-3" style={{ maxWidth: "400px", width: '100%' }}>
          <tr className="mb-3" style={rootColor}>
            <td>Name</td>
            <td>UHID</td>
            {/* <td>Age</td> */}
          </tr>
          <tr>
            <td>{paymentDetails?.Name}</td>
            <td>{paymentDetails?.UHID}</td>
            {/* <td>30</td> */}
          </tr>
        </table>
      </div>
      <div className="row mt-3">
        <div className="col-md-8">
          <div className="card p-4 mt-4 mb-4" style={paymentSummaryStyle}>
            <h5>
              <b>Payment Summary</b>
            </h5>
            <div
              className="card-collection d-flex justify-content-between mt-1"
              style={{ borderBottom: "1px solid #33333333" }}
            >
              <p>{CollectionType[paymentDetails?.advanceType] || ""}</p>
              <p>₹{(+paymentDetails?.amount)?.toFixed(2)}</p>
            </div>
            {/* <hr /> */}
            <div className="net-payable d-flex justify-content-between mt-3">
              <h6>
                <b>Net Payable</b>
              </h6>
              <h6>
                <b>₹{(+paymentDetails?.amount).toFixed(2)}</b>
              </h6>
            </div>
          </div>
        </div>
      </div>
      <button
        type="submit"
        disabled={!paymentDetails?.amount}
        onClick={() => callPayment()}
        style={{ ...rootButton, padding: "8px 50px" }}
      >
        Proceed To Pay
      </button>
    </div>
  );
}

export default PayementSummary;

var paymentSummaryStyle = {
  borderColor: "#007DC6",
  backgroundColor: "#E4F1F9",
};
