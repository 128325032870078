import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { cardOrientation, rootButton } from "assets/util/common/root";
import FeedIcon from "assets/util/Root/FeedIcon";
import { Icon } from "@iconify/react";
import { toast } from "react-hot-toast";
import { callGet } from "common/local";
import API from "util/API/api";
import { postAPIData } from "Hooks/Services";
import Loader from "Pages/Loader";
const isOnlyDigits = (data) => {
  data = data?.trim();
  if (!data) return true;
  return /^\d+$/.test(data);
};
const Feedback = () => {
  const [expanded, setExpanded] = React.useState(false);
  const [selected, setSelected] = useState(0);
  const [feedbackText, setFeedbackText] = useState("");
  const [feedBackResp, setFeedBackResp] = useState({});
  const [loading, setLoading] = useState(false);
  const authData = callGet("auth");
  const handleChange = (panel) => (event, isExpanded) => {
    setSelected(panel);
    setExpanded(isExpanded ? panel : false);
  };

  const [feedBackData, setFeedBackData] = useState([]);
  const postFeedBack = async (data) => {
    try {
      setLoading(true);
      data = { ...data, ...callGet("auth") };
      const resp = await postAPIData({ ...API.postFeedBack(data) });
      if (!resp?.data?.IsSuccessful) {
        throw resp?.data;
      } else {
        setFeedBackResp({});
        setFeedbackText("");
        setExpanded(false);
        window.scroll(0, 0);
        toast.success("Feedback saved");
      }
    } catch (err) {
      console.error(err?.FriendlyMessage || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };
  const getFeedbackOptions = async () => {
    try {
      const data = { ...callGet("auth") };
      setLoading(true);
      const resp = await postAPIData({ ...API.getFeedBackOptions(data) });
      if (!resp?.data?.IsSuccessful) {
        throw resp?.data;
      }
      setFeedBackData(resp?.data?.Data);
    } catch (err) {
      toast.error(err?.FriendlyMessage || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getFeedbackOptions();
  }, []);
  const handleSaveData = () => {
    let respArr = Object.values(feedBackResp);
    let isValidRemark = isOnlyDigits(feedbackText);
    if (respArr?.length || !isValidRemark) {
      postFeedBack({
        Remark: feedbackText,
        FacilityId: authData?.hospitalId,
        Questions: respArr,
      });
    } else {
      if (isValidRemark && feedbackText) {
        toast.error("Please enter valid feedback");
      } else {
        toast.error("Please enter feedback");
      }
    }
  };
  return (
    <div className="container-fluid mt-4  p-4" style={cardOrientation}>
      <div className="row">
        <h2 style={{ color: "#007DC6", fontWeight: "500", fontSize: "30px" }}>
          Patients FeedBack
        </h2>
        <p style={{ opacity: "0.7" }}>Please Rate The Following</p>
      </div>
      <div className="row feedbackList">
        {feedBackData?.map((value, i) => {
          if (!value?.Questions?.[0]?.OptionCategory) return;
          return (
            <React.Fragment key={i}>
              <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12 mt-2 mb-2">
                <Accordion
                  expanded={
                    expanded === value.QuestionCategoryId &&
                    value.QuestionCategoryId === selected
                  }
                  onChange={handleChange(value.QuestionCategoryId)}
                >
                  <AccordionSummary
                    expandIcon={<FeedIcon />}
                    // aria-controls="panel1bh-content"
                    // id="panel1bh-header"
                    // id = {value.id}
                  >
                    <Typography className="feedback-accordion-header">
                      {value?.QuestionCategory || value?.QuestionName || ""}
                    </Typography>
                  </AccordionSummary>
                  {value?.Questions?.map((ques, index) => {
                    return (
                      <AccordionDetails key={index}>
                        <div className="subtitlename">
                          {ques?.QuestionName || ""}
                        </div>
                        {ques?.OptionCategory?.map((options, optionIndex) => {
                          return (
                            <div key={optionIndex + index}>
                              <div className="row flex-start">
                                <div className="col-12">
                                  {options?.OptionCategory === "Emoticons" && (
                                    <div className="emojicss">
                                      {options?.Options?.map(
                                        (emoteOptions, emoteIndex) => {
                                          return (
                                            <div
                                              key={
                                                optionIndex + index + emoteIndex
                                              }
                                              onClick={() => {
                                                let str = `${value.QuestionCategoryId}-${ques?.QuestionId}`;
                                                setFeedBackResp((data) => {
                                                  let res = { ...data };
                                                  if (res[str]) {
                                                    res[str] = {
                                                      ...res[str],
                                                      OptionId:
                                                        emoteOptions?.OptionId,
                                                    };
                                                  } else {
                                                    res[str] = {
                                                      OptionId:
                                                        emoteOptions?.OptionId,
                                                      QuestionCategoryId:
                                                        value.QuestionCategoryId,
                                                      QuestionId:
                                                        ques?.QuestionId,
                                                      Remark: "",
                                                    };
                                                  }
                                                  return res;
                                                });
                                              }}
                                              className="col-3"
                                              style={{ cursor: "pointer" }}
                                            >
                                              <div
                                                className="text-center "
                                                style={{
                                                  float: "left",
                                                }}
                                              >
                                                <p style={{ fontSize: "13px" }}>
                                                  {emoteOptions?.OptionName}
                                                </p>
                                                {emoteOptions?.OptionName ===
                                                  "Excellent" && (
                                                  <Icon
                                                    icon="icomoon-free:happy2"
                                                    style={{ fontSize: "25px" }}
                                                    color={`${
                                                      feedBackResp[
                                                        `${value.QuestionCategoryId}-${ques?.QuestionId}`
                                                      ]?.OptionId ===
                                                      emoteOptions?.OptionId
                                                        ? "green"
                                                        : "gray"
                                                    }`}
                                                  />
                                                )}
                                                {emoteOptions?.OptionName ===
                                                  "Good" && (
                                                  <Icon
                                                    icon="iconamoon:slightly-smiling-face-fill"
                                                    style={{ fontSize: "25px" }}
                                                    color={`${
                                                      feedBackResp[
                                                        `${value.QuestionCategoryId}-${ques?.QuestionId}`
                                                      ]?.OptionId ===
                                                      emoteOptions?.OptionId
                                                        ? "green"
                                                        : "gray"
                                                    }`}
                                                  />
                                                )}
                                                {emoteOptions?.OptionName ===
                                                  "Need Improve" && (
                                                  <Icon
                                                    icon="fa6-solid:face-meh"
                                                    style={{ fontSize: "25px" }}
                                                    color={`${
                                                      feedBackResp[
                                                        `${value.QuestionCategoryId}-${ques?.QuestionId}`
                                                      ]?.OptionId ===
                                                      emoteOptions?.OptionId
                                                        ? "orange"
                                                        : "gray"
                                                    }`}
                                                  />
                                                )}
                                                {emoteOptions?.OptionName ===
                                                  "Poor" && (
                                                  <Icon
                                                    icon="iconamoon:frowning-face-fill"
                                                    style={{ fontSize: "25px" }}
                                                    color={`${
                                                      feedBackResp[
                                                        `${value.QuestionCategoryId}-${ques?.QuestionId}`
                                                      ]?.OptionId ===
                                                      emoteOptions?.OptionId
                                                        ? "red"
                                                        : "gray"
                                                    }`}
                                                  />
                                                )}
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  )}
                                  {(options?.OptionCategory === "YesNo" ||
                                    options?.OptionCategory === "Thumbs") && (
                                    <div className="likedislike">
                                      {options?.Options?.map(
                                        (optionData, optionData_index) => {
                                          return (
                                            <div
                                              key={optionData_index}
                                              onClick={() => {
                                                let str = `${value.QuestionCategoryId}-${ques?.QuestionId}`;
                                                setFeedBackResp((data) => {
                                                  let res = { ...data };
                                                  if (res[str]) {
                                                    res[str] = {
                                                      ...res[str],
                                                      OptionId:
                                                        optionData?.OptionId,
                                                    };
                                                  } else {
                                                    res[str] = {
                                                      OptionId:
                                                        optionData?.OptionId,
                                                      QuestionCategoryId:
                                                        value.QuestionCategoryId,
                                                      QuestionId:
                                                        ques?.QuestionId,
                                                      Remark: "",
                                                    };
                                                  }
                                                  return res;
                                                });
                                              }}
                                              className="col-3"
                                              style={{ cursor: "pointer" }}
                                            >
                                              <div
                                                className="text-center display-flex"
                                                style={{
                                                  float: "left",
                                                }}
                                              >
                                                {(optionData?.OptionName ===
                                                  "Yes" ||
                                                  optionData?.OptionName ===
                                                    "Like") && (
                                                  <Icon
                                                    icon="mdi:like"
                                                    style={{ fontSize: "25px" }}
                                                    color={`${
                                                      feedBackResp[
                                                        `${value.QuestionCategoryId}-${ques?.QuestionId}`
                                                      ]?.OptionId ===
                                                      optionData?.OptionId
                                                        ? "orange"
                                                        : "gray"
                                                    }`}
                                                  />
                                                )}
                                                {(optionData?.OptionName ===
                                                  "No" ||
                                                  optionData?.OptionName ===
                                                    "Dislike") && (
                                                  <Icon
                                                    icon="mdi:dislike"
                                                    style={{ fontSize: "25px" }}
                                                    color={`${
                                                      feedBackResp[
                                                        `${value.QuestionCategoryId}-${ques?.QuestionId}`
                                                      ]?.OptionId ===
                                                      optionData?.OptionId
                                                        ? "orange"
                                                        : "gray"
                                                    }`}
                                                  />
                                                )}
                                                <p style={{ fontSize: "13px" }}>
                                                  {optionData?.OptionName}
                                                </p>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  )}
                                  {options?.OptionCategory === "Hours" && (
                                    <select
                                      id="rootField"
                                      class="form-control"
                                      onChange={(e) => {
                                        {
                                          let str = `${value.QuestionCategoryId}-${ques?.QuestionId}`;
                                          setFeedBackResp((data) => {
                                            let res = { ...data };
                                            if (res[str]) {
                                              res[str] = {
                                                ...res[str],
                                                OptionId: e?.target?.value,
                                              };
                                            } else {
                                              res[str] = {
                                                OptionId: e?.target?.value,
                                                QuestionCategoryId:
                                                  value.QuestionCategoryId,
                                                QuestionId: ques?.QuestionId,
                                                Remark: "",
                                              };
                                            }
                                            return res;
                                          });
                                        }
                                      }}
                                      aria-label="Default select example"
                                    >
                                      <option value={""}>Select Option</option>
                                      {options?.Options?.length ? (
                                        options?.Options?.map((e, i) => (
                                          <option key={i} value={e?.OptionId}>
                                            {e?.OptionName}
                                          </option>
                                        ))
                                      ) : (
                                        <option value="">Loading...</option>
                                      )}
                                    </select>
                                  )}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <p
                                    style={{ marginBottom: "4px" }}
                                    className="feedback-accordion-header"
                                  >
                                    Remarks
                                  </p>
                                  <input
                                    onChange={(text) => {
                                      // setFeedBackData((e) => {
                                      //   let arr = [...e];
                                      //   arr[i] = {
                                      //     ...value,
                                      //     remarkResp: text?.target?.value,
                                      //   };
                                      //   return arr;
                                      // });
                                      // console.log(
                                      //   value.QuestionCategoryId,
                                      //   ques?.QuestionId,
                                      //   "text",
                                      //   text?.target?.value
                                      // );
                                      let textResp = isOnlyDigits(
                                        text?.target?.value
                                      );
                                      if (textResp) return;
                                      let str = `${value?.QuestionCategoryId}-${ques?.QuestionId}`;
                                      setFeedBackResp((data) => {
                                        let res = { ...data };
                                        if (res[str]) {
                                          res[str] = {
                                            ...res[str],
                                            Remark: text?.target?.value,
                                          };
                                        } else {
                                          res[str] = {
                                            OptionId: "",
                                            QuestionCategoryId:
                                              value?.QuestionCategoryId,
                                            QuestionId: ques?.QuestionId,
                                            Remark: text?.target?.value,
                                          };
                                        }
                                        return res;
                                      });
                                    }}
                                    className="form-control"
                                    type="text"
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </AccordionDetails>
                    );
                  })}
                </Accordion>
              </div>
            </React.Fragment>
          );
        })}
        <div className="row">
          <div className="col-12">
            <div className="mt-3 mb-2">
              <label className="feedback-accordion-header" for="comment">
                FeedBack:
              </label>
              <textarea
                className="form-control"
                value={feedbackText}
                onChange={(e) => setFeedbackText(e.target.value)}
                rows="5"
                id="comment"
                name="text"
              ></textarea>
            </div>
            <div>
              <button
                disabled={loading}
                onClick={() => handleSaveData()}
                style={rootButton}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className="centerLoader2">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default Feedback;
