import React, { useEffect, useState } from "react";
import "./AppointmentTime.css";
import { cardOrientation } from "../../../../assets/util/common/cardOrientation";
import MainIcon from "../../../../assets/mainIcon/MainIcon";
import AppoinmentTimeDetails from "./AppoinmentTimeDetails";
import { useDispatch, useSelector } from "react-redux";
import { getImg } from "../../../../common/imageUrl";
import moment from "moment";
import { callGet } from "../../../../common/local";
import { Months, weekDays } from "../../../../common/days";
import API from "../../../../util/API/api";
import { postThunk } from "../../../../Action/middleware/thunk";
import DoctorCard from "../DoctorCard/DoctorCard";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { TextField } from "@mui/material";
import { postAPIData } from "Hooks/Services";
import { toast } from "react-hot-toast";
import Loader from "Pages/Loader";
import { Icon } from "@iconify/react";

const AppointmentTime = () => {
  const dispatch = useDispatch();
  const docTiming = useSelector((state) => state.R_getDocsTimming);
  const [date, setDate] = useState(new Date());
  const authData = callGet("auth");
  var currentDocTimings = {};
  const [consultationTypeOptions, setConsultationTypeOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [ConsultationType, setConsultationType] = useState(2);
  const docDetails = useSelector(
    (state) => state.R_getDocsTimming?.doctorDetail
  );
  const [takeAppointment, setTakeAppointment] = useState({
    DoctorId: docDetails.DoctorId,
    FacilityId: authData?.selectedHospitalId,
    AppointmentTypeID: "",
    Date: moment().format("YYYY-MM-D"),
    ...authData,
  });
  const getAppointementTypes = async () => {
    try {
      setLoading(true);
      const data = { ...authData };
      const resp = await postAPIData({ ...API.getAppointmentTypes(data) });
      if (!resp?.data?.IsSuccessful) {
        throw resp?.data;
      }
      setTakeAppointment({
        ...takeAppointment,
        AppointmentTypeID: resp?.data?.Data?.[0]?.ID,
        AppointmentTypeCode: resp?.data?.Data?.[0]?.Code,
      });
      setConsultationTypeOptions(resp?.data?.Data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const getAppointementTypesDetails = async () => {
    try {
      setLoading(true);
      const data = { ...authData };
      const respDetails = await postAPIData({
        ...API.getAppointmentTypesDetails({
          ...data,
          DoctorId: takeAppointment?.DoctorId,
          AppointmentId: takeAppointment?.AppointmentTypeID,
        }),
      });
      if (!respDetails?.data?.IsSuccessful) {
        toast.error(
          respDetails?.data?.FriendlyMessage || "Something went wrong"
        );
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getAppointementTypes();
  }, []);
  useEffect(() => {
    // getAppointementTypesDetails();
    dispatch({ type: "REMOVE_DATA" });
    if (takeAppointment?.AppointmentTypeID) {
      dispatch(postThunk(API.A_getDocsTimming(takeAppointment)));
    }
  }, [
    takeAppointment?.DoctorId,
    takeAppointment?.AppointmentTypeID,
    takeAppointment?.Date,
  ]);
  const onKeyDown = (e) => {
    e.preventDefault();
  };
  const getDocTimings = (data) => {
    let current = new Date()
      .toLocaleDateString("en-us", { weekday: "long" })
      .substring(0, 3)
      .toLocaleUpperCase();
    for (let key of data) {
      if (key?.Day === current) {
        currentDocTimings = key;
        return "worked";
      }
    }
    return "";
  };

  return (
    <div
      className="container-fluid  bg-light"
      style={{ ...cardOrientation, position: "relative" }}
    >
      <MainIcon bottom={0} right={20} width={300} zIndex={"-1"} />
      <div className="row mt-2 py-4 make-a-call">
        <div className="col-12">
          <div className="row align-items-center justify-content-between">
            <div className="col-sm-8 col-12 ">
              <div className="row align-items-center justify-content-start">
                <div className="col-12 col-sm-7 d-flex ipadview">
                  <div className="image">
                    <img
                      id="doctor-profile"
                      draggable="false"
                      src={getImg(docDetails.DoctorPic || "")}
                      alt="doctor img"
                    />
                  </div>
                  <div className="description ms-3">
                    <h6 style={{ marginBottom: "2px" }}>
                      {docDetails?.DoctorName}
                    </h6>
                    <p style={{ marginBottom: "2px" }}>
                      {docDetails?.SpecialisationName}
                    </p>
                    {/* <p style={{ fontSize: ".8rem" }}>Thus : 12.01 PM to 6.30 PM</p> */}
                    {docTiming?.data[0]?.WeeklySchedule?.length ? (
                      <p style={{ fontSize: ".8rem" }}>
                        {getDocTimings(docTiming?.data[0]?.WeeklySchedule) ? (
                          <React.Fragment>
                            {currentDocTimings?.Day} :{" "}
                            {currentDocTimings?.Schedule}
                          </React.Fragment>
                        ) : (
                          ""
                        )}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-5 calltime">
                  <p
                    style={{
                      fontSize: "18px",
                      lineHeight: "24px",
                      margin: "0px",
                    }}
                  >
                    <a href="tel:18003099999">1800 309 9999 </a>
                    <br />
                    <span style={{ fontSize: "15px" }}>Make a Call</span>{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-12 weekday d-flex">
              <div style={{ width: '100%' }}>
                <div style={{ visibility: "hidden", height: "1px" }}>
                  {" "}
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      open={open}
                      onClose={() => setOpen(false)}
                      orientation="portrait"
                      value={date}
                      inputFormat="DD/MM/yyyy"
                      minDate={new Date(+new Date().setHours(0, 0, 0, 0))}
                      onChange={(newValue) => {
                        setDate((a) => newValue);
                        setTakeAppointment({
                          ...takeAppointment,
                          DoctorId: docDetails.DoctorId,
                          Date: moment(newValue).format("YYYY-MM-D"),
                        });
                      }}
                      renderInput={(params) => (
                        <TextField onKeyDown={onKeyDown} {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="date-container">
                  {(
                    <Icon
                      fontSize={30}
                      style={{
                        cursor: "pointer", pointerEvents: !(new Date(new Date(date).setHours(0, 0, 0, 0)) >
                          new Date(new Date().setHours(0, 0, 0, 0))) && 'none', color: !(new Date(new Date(date).setHours(0, 0, 0, 0)) >
                          new Date(new Date().setHours(0, 0, 0, 0))) && '#b5b5b5' 
                      }}
                      onClick={() => {
                        setDate((prevDate) => {
                          if (!prevDate) return prevDate;
                          prevDate = new Date(
                            moment(prevDate).format("YYYY-MM-D")
                          );
                          const newDate = new Date(prevDate);
                          newDate.setDate(+prevDate?.getDate() + 1);
                          newDate?.setDate(prevDate?.getDate() - 1);
                          setTakeAppointment((prev) => ({
                            ...prev,
                            DoctorId: docDetails?.DoctorId,
                            Date: moment(newDate).format("YYYY-MM-D"),
                          }));
                          return new Date(newDate);
                        });
                      }}
                      icon="carbon:previous-outline"
                    />
                  )}
                  <p
                    style={{ cursor: "pointer" }}
                    onClick={() => setOpen(true)}
                  >
                    {moment(date).date()} {Months[moment(date).month()]}{" "}
                    {moment(date).year()}, {weekDays[moment(date).day()]}
                  </p>
                  <Icon
                    fontSize={30}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setDate((prevDate) => {
                        if (!prevDate) return prevDate;
                        prevDate = new Date(
                          moment(prevDate).format("YYYY-MM-D")
                        );
                        const newDate = new Date(prevDate);
                        newDate.setDate(+prevDate?.getDate() + 1);
                        setTakeAppointment({
                          ...takeAppointment,
                          DoctorId: docDetails.DoctorId,
                          Date: moment(newDate).format("YYYY-MM-D"),
                        });
                        return new Date(newDate);
                      });
                    }}
                    icon="carbon:next-outline"
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row  align-items-center text-center py-4">
            <div className="button_group">
              <span>
                {consultationTypeOptions?.length > 0 &&
                  consultationTypeOptions?.map((e, i) => {
                    return (
                      <button
                        key={i}
                        type="button"
                        onClick={() => {
                          setConsultationType(e?.ID);
                          setTakeAppointment((prev) => ({
                            ...prev,
                            AppointmentTypeID: e?.ID,
                            AppointmentTypeCode: e?.Code,
                          }));
                        }}
                        className={
                          ConsultationType === e?.ID
                            ? "consult_btn"
                            : "consult_btn_unselected"
                        }
                      >
                        {e?.Name}
                      </button>
                    );
                  })}
              </span>
            </div>
          </div>
          {docTiming.loading || loading ? (
            <div className="centerLoader2">
              <Loader />
            </div>
          ) : (
            <div className="row">
              {docTiming?.data?.[0]?.Timings?.length ? (
                <AppoinmentTimeDetails
                  takeAppointment={takeAppointment}
                  data={docTiming?.data?.[0]}
                />
              ) : (
                <AppoinmentTimeNoDetails data={takeAppointment} />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppointmentTime;

function AppoinmentTimeNoDetails({ data }) {
  const [loading, setLoading] = useState(false);
  const [DoctorList, setDoctorList] = useState([]);
  const docDetails = useSelector(
    (state) => state.R_getDocsTimming?.doctorDetail
  );
  const getDoctorData = async (data) => {
    try {
      setLoading(true);
      const profileResp = await postAPIData({
        ...API.getRelatedDoctorList({
          ...data,
        }),
      });
      let resp = JSON.parse(profileResp?.data);
      if (!resp?.IsSuccessful) {
        setDoctorList([]);
      }
      setDoctorList(JSON.parse(profileResp?.data)?.Data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    var body = { ...callGet("auth") };
    if (docDetails?.DoctorId) {
      getDoctorData({
        ...body,
        DoctorId: docDetails?.DoctorId,
        Date: data?.Date,
        SpecialisationId: docDetails?.SpecialisationId,
        HospitalId: body?.selectedHospitalId || body?.hospitalId,
      });
    }
  }, [docDetails, data]);
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-8 mx-auto m-4 text-center text-secondary">
          <p>
            No Slots are available for {docDetails?.DoctorName} on{" "}
            {moment(data.Date).date()} {Months[moment(data.Date).month()]}{" "}
            {moment(data.Date).year()}. please select alternative appointment
            date or choose from available online slots for the following doctor
          </p>
        </div>
      </div>
      <div className="row align-items-center justify-content-center px-3 pb-5">
        {DoctorList?.length ? (
          // preventing to show same doctor
          DoctorList?.map((value, i) => {
            return <DoctorCard key={i} date={data?.Date} data={value} />;
          })
        ) : (
          <p className="text-center"></p>
        )}
      </div>
      {/* )} */}
    </React.Fragment>
  );
}
